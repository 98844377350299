import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { clearError } from '../../actions/Error';
import { List } from '../../components/lists/List'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import "./addlistitem.scss";
import  ProfileUpdateForm  from '../../components/profile/Profile';
import  AddItemForm from '../../components/additemform/AddItemForm'
import { UPDATE_USER } from '../../actions/Types';
import axios from 'axios';
import { baseURL } from '../../config';
import  DocumentInput from './Documents';
import  ImageInput from './Images';

 const AddListItem = ({isAuthenticated,isError,user,loading,updateUser,access}) => {
    const [activeTab, setActiveTab] = useState(1);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    // console.log(user,access);

    const token  = access || {};
    const userinf = user || {};
  

    const validateFieldsInTab = (tab) => {
        console.log('validate the tab')
        let isValidTab = true;
        
        if (tab === 1) {
            // Validate fields in Basic Info tab
            console.log(formData.propertyName, formData.discription, formData.plotNo, formData.surveyNumber, formData.pattaNumber, formData.subdivisionNumber, formData.buildingOrFlatNo, formData.village)
            isValidTab = handleValidation('propertyName', formData.propertyName) && isValidTab;
            console.log(isValidTab,'--------------->')
            isValidTab = handleValidation('discription', formData.discription) && isValidTab;
            isValidTab = handleValidation('plotNo', formData.plotNo) && isValidTab;
            isValidTab = handleValidation('surveyNumber', formData.surveyNumber) && isValidTab;
            isValidTab = handleValidation('pattaNumber', formData.pattaNumber) && isValidTab;
            isValidTab = handleValidation('subdivisionNumber', formData.subdivisionNumber) && isValidTab;
            isValidTab = handleValidation('buildingOrFlatNo', formData.buildingOrFlatNo) && isValidTab;
            isValidTab = handleValidation('village', formData.village) && isValidTab;
            // Add more validation for fields in Basic Info tab
        } else if (tab === 2) {
            // Validate fields in Location Details tab
            isValidTab = handleValidation('country', formData.country) && isValidTab;
            isValidTab = handleValidation('state', formData.state) && isValidTab;
            isValidTab = handleValidation('city', formData.city) && isValidTab;
            isValidTab = handleValidation('address1', formData.address1, 3) && isValidTab; 
            isValidTab = handleValidation('address2', formData.address2, 3) && isValidTab; 
            isValidTab = handleValidation('pincode', formData.pincode, 6) && isValidTab; 
            isValidTab = handleValidation('locality', formData.locality, 3) && isValidTab; 
            isValidTab = handleValidation('street', formData.street, 3) && isValidTab; 
            isValidTab = handleValidation('latitude', formData.latitude, isValidCoordinate) && isValidTab; 
            isValidTab = handleValidation('longitude', formData.longitude, isValidCoordinate) && isValidTab;
            // Add more validation for fields in Location Details tab
        } else if (tab === 3) {
            // Validate fields in Property Value tab
            isValidTab = handleValidation('purchaseValue', formData.purchaseValue, isValidCurrency) && isValidTab; 
            isValidTab = handleValidation('purchaseDate', formData.purchaseDate, isValidDate) && isValidTab;
            isValidTab = handleValidation('guidelineValue', formData.guidelineValue, isValidCurrency) && isValidTab; 
            isValidTab = handleValidation('marketValue', formData.marketValue, isValidCurrency) && isValidTab; 
            isValidTab = handleValidation('rentalValue', formData.rentalValue, isValidCurrency) && isValidTab;
            isValidTab = handleValidation('age', formData.age, isNumeric) && isValidTab; 
            isValidTab = handleValidation('propertyTaxNumber', formData.propertyTaxNumber, isAlphaNumeric) && isValidTab; 
            isValidTab = handleValidation('propertyTax', formData.propertyTax, isValidCurrency) && isValidTab; 
            isValidTab = handleValidation('waterTax', formData.waterTax, isValidCurrency) && isValidTab;
            isValidTab = handleValidation('waterTaxNumber', formData.waterTaxNumber, isAlphaNumeric) && isValidTab;
            isValidTab = handleValidation('propertyInsurance', formData.propertyInsurance, isAlphaNumeric) && isValidTab;
            isValidTab = handleValidation('insuranceExpiryDate', formData.insuranceExpiryDate, isValidDate) && isValidTab;
        } else if ( tab === 4) {
            // Validate fields in Property Area tab
            isValidTab = handleValidation('propertyType', formData.propertyType) && isValidTab;
            isValidTab = handleValidation('bhkType', formData.bhkType) && isValidTab;
            isValidTab = handleValidation('ownershipType', formData.ownershipType) && isValidTab;
            isValidTab = handleValidation('apartmentName', formData.apartmentName, 3) && isValidTab;
            isValidTab = handleValidation('builtUpArea', formData.builtUpArea, isNumericWithTwoDecimals) && isValidTab; 
            isValidTab = handleValidation('propertyArea', formData.propertyArea, isNumericWithTwoDecimals) && isValidTab; 
            isValidTab = handleValidation('carpetArea', formData.carpetArea, isNumericWithTwoDecimals) && isValidTab;
            isValidTab = handleValidation('propertyAge', formData.propertyAge, isNumeric) && isValidTab;
            isValidTab = handleValidation('facing', formData.facing, 3) && isValidTab;
            isValidTab = handleValidation('floorType', formData.floorType, 3) && isValidTab; 
            isValidTab = handleValidation('floor', formData.floor, 3) && isValidTab; 
            isValidTab = handleValidation('totalFloors', formData.totalFloors, isNumeric) && isValidTab; 
            isValidTab = handleValidation('furnishing', formData.furnishing, 3) && isValidTab; 
            isValidTab = handleValidation('parking', formData.parking, 3) && isValidTab; 
            isValidTab = handleValidation('kitchenType', formData.kitchenType, 3) && isValidTab;
            isValidTab = handleValidation('maintenanceCost', formData.maintenanceCost, 3) && isValidTab; 
        }
        // Add more tab validations as needed

        return isValidTab;
    };

    const isValidCurrency = (value) => {
    return /^\d+(\.\d{2})?$/.test(value); // Checks for numeric with two decimal places
    };

    const isValidDate = (value) => {
        return /^\d{4}-\d{2}-\d{2}$/.test(value); // Checks for YYYY-MM-DD date format
    };

    const isAlphaNumeric = (value) => {
        return /^[a-z0-9]+$/i.test(value); // Alphanumeric validation
    };

    const isNumeric = (value) => {
        return /^\d+$/.test(value); // Numeric validation
    };

    function isNumericWithTwoDecimals(value) {
        const regex = /^\d+(\.\d{1,2})?$/;
        return regex.test(value);
    }

   
    const isValidCoordinate = (value) => {
        const regex = /^-?\d+(\.\d+)?$/;
        return regex.test(value);  // Checks if the value is a valid latitude/longitude format
    };

    

    const handleTabClick = (tab) => {
        // Validate current tab before moving to the next
        const isCurrentTabValid = validateFieldsInTab(activeTab);
        console.log(isCurrentTabValid)

        if (isCurrentTabValid) {
            setActiveTab(tab); // Allow tab switch
        } else {
            // Highlight the error in the current tab
            setIsValid((prev) => ({ ...prev, [`tab${activeTab}`]: false }));
        }
    };
    // console.log(token,userinf)
  
  
    const [types, setTypes] = useState({
      property_types: [],
      bhk_types: [],
      ownership_types: [],
      document_types: [],
    });
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [locations, setLocations] = useState([]);
    const [selectedPropertyType, setSelectedPropertyType] = useState('');
    const [selectedBHKType, setSelectedBHKType] = useState('');
    const [selectedOwnershipType, setSelectedOwnershipType] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedcity, setSelectedcity] = useState('');
  
    const [formData, setFormData] = useState({
      // Existing fields
      propertyName: '',
      plotNo: '',
      surveyNumber: '',
      pattaNumber: '',
      subdivisionNumber: '',
      buildingOrFlatNo: '',
      village: '',
      country: '',
      state: '',
      city: '',
      address1: '',
      address2: '',
      pincode: '',
      locality: '',
      street:'',
      coordinates: '',
      purchaseValue: '',
      purchaseDate: '',
      guidelineValue: '',
      marketValue: '',
      rentalValue: '',
      age: '',
      propertyTaxNumber: '',
      propertyTax: '',
      waterTax: '',
      waterTaxNumber: '',
      propertyInsurance: '',
      insuranceExpiryDate: '',
      apartmentName: '',
      builtUpArea: '',
      propertyArea:'',
      carpetArea: '',
      propertyAge: '',
      facing: '',
      floorType: '',
      floor: '',
      totalFloors: '',
      furnishing: '',
      parking: '',
      kitchenType: '',
      maintenanceCost: '',
      discription:'',
      user_id:'',
      latitude:'',
      longitude:'',
      propertyType:'',
      bhkType:'',
      ownershipType:'',
      
      // New fields
      images: [], // Array to hold image files
      documents: [], // Array to hold document files
      documentImages: [], // Array to hold document images
      documentPdfs: [] // Array to hold document PDFs
    });
    
    const [isValid, setIsValid] = useState({
        propertyName: true,
        plotNo: true,
        surveyNumber: true,
        pattaNumber: true,
        subdivisionNumber: true,
        buildingOrFlatNo: true,
        village: true,
        country: true,
        state: true,
        city: true,
        address1: true,
        address2: true,
        pincode: true,
        locality: true,
        street: true,
        coordinates: true,
        purchaseValue: true,
        purchaseDate: true,
        guidelineValue: true,
        marketValue: true,
        rentalValue: true,
        age: true,
        propertyTaxNumber: true,
        propertyTax: true,
        waterTax: true,
        waterTaxNumber: true,
        propertyInsurance: true,
        insuranceExpiryDate: true,
        apartmentName: true,
        builtUpArea: true,
        propertyArea: true,
        carpetArea: true,
        propertyAge: true,
        facing: true,
        floorType: true,
        floor: true,
        totalFloors: true,
        furnishing: true,
        parking: true,
        kitchenType: true,
        maintenanceCost: true,
        discription: true,
        user_id: true,
        latitude: true,
        longitude: true,
        propertyType:true,
        bhkType:true,
        ownershipType:true,
    });

    const handleValidation = (name, value) => {
        console.log('==================================>'.name,value)
        let validStatus = true;

        switch (name) {
            case 'propertyName':
            case 'apartmentName':
            case 'village':
            case 'locality':
            case 'street':
            case 'facing':
            case 'floorType':
            case 'furnishing':
            case 'kitchenType':
            case 'parking':
                validStatus = value.length >= 3; // Minimum 3 characters
                break;
            case 'plotNo':
            case 'pattaNumber':
            case 'subdivisionNumber':
            case 'buildingOrFlatNo':
            case 'propertyTaxNumber':
            case 'waterTaxNumber':
            case 'surveyNumber':
            case 'propertyInsurance':
                validStatus = /^[a-zA-Z0-9\s]+$/.test(value); // Alphanumeric
                break;
            case 'purchaseValue':
            case 'guidelineValue':
            case 'marketValue':
            case 'rentalValue':
            case 'age':
            case 'propertyTax':
            case 'waterTax':
            case 'builtUpArea':
            case 'propertyArea':
            case 'carpetArea':
            case 'propertyAge':
            case 'floor':
            case 'totalFloors':
            case 'maintenanceCost':
                validStatus = /^\d+(\.\d{1,2})?$/.test(value); // Numeric (with up to 2 decimal places)
                break;
            case 'pincode':
                validStatus = /^\d{6}$/.test(value); // 6-digit pincode
                break;
            case 'coordinates':
            case 'latitude':
            case 'longitude':
                validStatus = /^-?\d{1,3}\.\d+$/.test(value); // Lat/Long coordinate pattern
                break;
            case 'purchaseDate':
            case 'insuranceExpiryDate':
                validStatus = /\d{4}-\d{2}-\d{2}/.test(value); // YYYY-MM-DD format
                break;
            case 'country':
                validStatus=selectedCountry.length > 0;
                break;
            case 'state':
                validStatus=selectedState.length > 0;
                break;
            case 'city':
                validStatus = selectedcity.length > 0; // Not empty
                break;
            case 'propertyType':
                validStatus = selectedPropertyType.length > 0;
                break;
            case 'bhkType':
                validStatus = selectedBHKType.length > 0;
                break;
            case 'ownershipType':
                validStatus = selectedOwnershipType.length > 0;
                break;
            case 'address1':
            case 'address2':
            case 'discription':
                validStatus = value.length >= 5; // Minimum 5 characters
                break;
            case 'user_id':
                validStatus = value !== ''; // Ensure user_id is not empty
                break;
            default:
                validStatus = true;
                break;
        }

        setIsValid((prev) => ({ ...prev, [name]: validStatus }));

        return validStatus;
    };


    const checkFormValidity = () => {
        const formValid = Object.values(isValid).every((valid) => valid === true);
        setIsFormValid(formValid); // State to control the Save button
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        handleValidation(name, value);
        // After each input change, check the overall form validity
        checkFormValidity();
    };
  
    const handleFileChange = (e) => {
      const { name, files } = e.target;
      setFormData({ ...formData, [name]: Array.from(files) });
    };
  
    // const handleSubmitForm = async (e) => {
    //   e.preventDefault();
      
    //   const _doc =  formData.documents.map(doc => ({
    //     document_image: doc.documentImage || null,
    //     document_pdf: doc.documentPdf || null,
    //     document_type: doc.documentType, // Assuming this is an ID
    //     property_type: selectedPropertyType, // Assuming this is an ID
    //   })) 
    //   const _images = formData.images.map(img => ({
    //     property_image: img.propertyImage || null, // Handle image field here
    //   }))

    //   console.log("afer the form submit",_images,_doc)
    //   const formDataToSend = {
    //       // Append existing fields as plain text
    //       property_name: formData.propertyName,
    //       survey_number: formData.surveyNumber,
    //       plot_number: formData.plotNo,
    //       patta_number: formData.pattaNumber,
    //       subdivision_number: formData.subdivisionNumber,
    //       building_or_flat_no: formData.buildingOrFlatNo,
    //       village: formData.village,
    //       address1: formData.address1,
    //       address2: formData.address2,
    //       postcode: formData.pincode,
    //       locality: formData.locality,
    //       purchase_price: formData.purchaseValue,
    //       purchase_date: formData.purchaseDate,
    //       guideline_value: formData.guidelineValue,
    //       market_value: formData.marketValue,
    //       rental_value: formData.rentalValue,
    //       age: formData.age,
    //       property_tax_no: formData.propertyTaxNumber,
    //       property_tax: formData.propertyTax,
    //       water_tax_no: formData.waterTaxNumber,
    //       water_tax: formData.waterTax,
    //       property_insurance: formData.propertyInsurance,
    //       insurance_expiry_date: formData.insuranceExpiryDate,
    //       apartment_name: formData.apartmentName,
    //       built_up_area: formData.builtUpArea,
    //       carpet_area: formData.carpetArea,
    //       property_area: formData.propertyArea,
    //       facing: formData.facing,
    //       floor_type: formData.floorType,
    //       floor: formData.floor,
    //       total_floor: formData.totalFloors,
    //       furnishing: formData.furnishing,
    //       parking: formData.parking,
    //       kitchen_type: formData.kitchenType,
    //       maintenance_cost: formData.maintenanceCost,
    //       country: selectedCountry,
    //       state: selectedState,
    //       city: selectedcity,
    //       bhk_type: selectedBHKType,
    //       property_type: selectedPropertyType,
    //       ownership_type: selectedOwnershipType,
          
    //       // Add documents and images arrays
    //       documents:_doc,
           
    //       images:_images,
    //     };
    //     console.log(formData)
    //     console.log(formDataToSend)
    //     try {
    //       const response = await fetch(`${baseURL}/api/create-property/`, {
    //         method: 'POST',
    //         body: JSON.stringify(formDataToSend),
    //         headers: {
    //           'Content-Type': 'application/json',
    //           'Accept': 'application/json',
    //           'Authorization': `JWT ${token}`,
    //         },
    //       });
    //       const data = await response.json();
    //       console.log('Success:', data);
    //     } catch (error) {
    //       console.error('Error:', error);
    //     }
        
    // };


    const formIsValid = Object.values(isValid).every((fieldValid) => fieldValid);

    const handleSubmitForm = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const formIsValid = Object.values(isValid).every((fieldValid) => fieldValid);
        //const formIsValid = Object.values(isValid).every((fieldValid) => fieldValid);
        // Create a new FormData object
        const formDataToSend = new FormData();
        if (formIsValid) {
            // Append plain text fields
            formDataToSend.append('property_name', formData.propertyName);
            formDataToSend.append('survey_number', formData.surveyNumber);
            formDataToSend.append('plot_number', formData.plotNo);
            formDataToSend.append('patta_number', formData.pattaNumber);
            formDataToSend.append('subdivision_number', formData.subdivisionNumber);
            formDataToSend.append('building_or_flat_no', formData.buildingOrFlatNo);
            formDataToSend.append('village', formData.village);
            formDataToSend.append('address1', formData.address1);
            formDataToSend.append('address2', formData.address2);
            formDataToSend.append('postcode', formData.pincode);
            formDataToSend.append('locality', formData.locality);
            formDataToSend.append('street', formData.street);
            formDataToSend.append('purchase_price', formData.purchaseValue);
            formDataToSend.append('purchase_date', formData.purchaseDate);
            formDataToSend.append('guideline_value', formData.guidelineValue);
            formDataToSend.append('market_value', formData.marketValue);
            formDataToSend.append('rental_value', formData.rentalValue);
            formDataToSend.append('age', formData.age);
            formDataToSend.append('property_tax_no', formData.propertyTaxNumber);
            formDataToSend.append('property_tax', formData.propertyTax);
            formDataToSend.append('water_tax_no', formData.waterTaxNumber);
            formDataToSend.append('water_tax', formData.waterTax);
            formDataToSend.append('property_insurance', formData.propertyInsurance);
            formDataToSend.append('insurance_expiry_date', formData.insuranceExpiryDate);
            formDataToSend.append('apartment_name', formData.apartmentName);
            formDataToSend.append('built_up_area', formData.builtUpArea);
            formDataToSend.append('carpet_area', formData.carpetArea);
            formDataToSend.append('property_area', formData.propertyArea);
            formDataToSend.append('facing', formData.facing);
            formDataToSend.append('floor_type', formData.floorType);
            formDataToSend.append('floor', formData.floor);
            formDataToSend.append('total_floor', formData.totalFloors);
            formDataToSend.append('furnishing', formData.furnishing);
            formDataToSend.append('parking', formData.parking);
            formDataToSend.append('kitchen_type', formData.kitchenType);
            formDataToSend.append('maintenance_cost', formData.maintenanceCost);
            formDataToSend.append('discription', formData.discription);
            formDataToSend.append('latitude', formData.latitude);
            formDataToSend.append('longitude', formData.longitude);
            formDataToSend.append('country', selectedCountry);
            formDataToSend.append('state', selectedState);
            formDataToSend.append('city', selectedcity);
            formDataToSend.append('bhk_type', selectedBHKType);
            formDataToSend.append('property_type', selectedPropertyType);
            formDataToSend.append('ownership_type', selectedOwnershipType);
            formDataToSend.append('user_id', userinf.id);
            
            // Append documents
            formData.documents.forEach((doc, index) => {
            if (doc.documentImage) {
                formDataToSend.append(`documents[${index}][document_image]`, doc.documentImage);
            }
            if (doc.documentPdf) {
                formDataToSend.append(`documents[${index}][document_pdf]`, doc.documentPdf);
            }
            console.log(`Document Type for index ${index}: `, doc.documentType);

            formDataToSend.append(`documents[${index}][document_type]`, doc.documentType);
            formDataToSend.append(`document_type`, doc.documentType);
            formDataToSend.append(`documents[${index}][property_type]`, selectedPropertyType);
            });
        
            // Append images
            formData.images.forEach((img, index) => {
            if (img.propertyImage) {
                formDataToSend.append(`images[${index}][property_image]`, img.propertyImage);
            }
            });
        
            console.log("Before the form submit", formDataToSend);
            
            try {
                const response = await fetch(`${baseURL}/api/create-property/`, {
                    method: 'POST',
                    body: formDataToSend,
                    headers: {
                    'Authorization': `JWT ${token}`,
                    
                    // Note: Content-Type is not set for FormData; it's automatically set by the browser
                    },
                });
            
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                
                const data = await response.json();
                console.log('Success:', data);
                // Navigate to the new route
                navigate(`/${data.property.property_id}`, { state: { propertyData: data, userinfo: userinf } });
            } catch (error) {
            console.error('Error:', error);
            }finally {
                setIsLoading(false); // Stop loading animation
            }
        } else {
            console.log('Form contains errors');
        }
    
      };
  
    useEffect(() => {
      // Fetch all types when the component loads
      const fetchTypes = async () => {
        try {
          const config = {
              headers: {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json',
                  'Authorization': `JWT ${token}` // Add the Authorization header with the token
              }
          };
  
          
          
          const AllTypesResponse = await fetch(`${baseURL}/api/all-types/`,config);
          const AllTypesData = await AllTypesResponse.json();
          setTypes(AllTypesData);
          
          const CSCResponse = await fetch(`${baseURL}/api/all-locations/`,config);
          const CSCData = await CSCResponse.json();
          setLocations(CSCData);;
          // const documentTypesResponse = await fetch('/api/document-types');
          // const documentTypesData = await documentTypesResponse.json();
          // setDocumentTypes(documentTypesData);
  
          // const bhkTypesResponse = await fetch('/api/bhk-types');
          // const bhkTypesData = await bhkTypesResponse.json();
          // setBhkTypes(bhkTypesData);
  
          // const ownershipTypesResponse = await fetch('/api/ownership-types');
          // const ownershipTypesData = await ownershipTypesResponse.json();
          // setOwnershipTypes(ownershipTypesData);
        } catch (error) {
          console.error('Error fetching types:', error);
        }
      };
  
      fetchTypes();
        }, []);
    
        console.log(types)
    
    // const handleTabClick = (tabIndex) => {
    //     setActiveTab(tabIndex);
    //     };
    
    // Update states when a country is selected
    const handleCountryChange = (e) => {
        const countryId = e.target.value;
        setSelectedCountry(countryId);
        const selectedCountry = locations.find(country => country.id === parseInt(countryId));
        setStates(selectedCountry ? selectedCountry.states : []);
        setCities([]);  // Reset cities when country changes
        setSelectedState('');
        const validCountry = countryId !== ''; // Ensure country is not empty
        setIsValid((prev) => ({ ...prev, country: validCountry }));
    };
    
    // Update cities when a state is selected
    const handleStateChange = (e) => {
        const stateId = e.target.value;
        setSelectedState(stateId);
        const selectedState = states.find(state => state.id === parseInt(stateId));
        setCities(selectedState ? selectedState.cities : []);
        const validState = stateId !== ''; // Ensure country is not empty
        setIsValid((prev) => ({ ...prev, country: validState }));
    };
    
    const handleCityChange = (e) => {
        const cityId = e.target.value;
        setSelectedcity(cityId);

        const validCity = cityId !== ''; // Ensure city is selected
        setIsValid((prev) => ({ ...prev, city: validCity }));
    };

     // Validation for Property Type
    const handlePropertyTypeChange = (e) => {
        const value = e.target.value;
        setSelectedPropertyType(value);
        setIsValid((prev) => ({ ...prev, propertyType: value !== '' }));
    };

    // Validation for BHK Type
    const handleBHKTypeChange = (e) => {
        const value = e.target.value;
        setSelectedBHKType(value);
        setIsValid((prev) => ({ ...prev, bhkType: value !== '' }));
    };

    // Validation for Ownership Type
    const handleOwnershipTypeChange = (e) => {
        const value = e.target.value;
        setSelectedOwnershipType(value);
        setIsValid((prev) => ({ ...prev, ownershipType: value !== '' }));
    };


    const handleDocumentChange = (index, updatedDocument) => {
        console.log('Updated document at index:', index, updatedDocument);
        const updatedDocuments = formData.documents.map((doc, i) => (i === index ? updatedDocument : doc));
        console.log('Documents in the array:', updatedDocuments);
        setFormData({ ...formData, documents: updatedDocuments });
    };

    const handleImageChange = (index, updatedImage) => {
        console.log('Updated document at index:', index, updatedImage);
        const updatedImages = formData.images.map((img, i) => (i === index ? updatedImage : img));
        console.log('images in the array:', updatedImages);
        setFormData({ ...formData, images: updatedImages });
    };

    // const handleAddDocument = () => {
    //     // setFormData({ ...formData, documents: [...formData.documents, {}] });
    //     setFormData(prevState => ({
    //         ...prevState,
    //         documents: [...prevState.documents, { documentImage: '', documentPdf: '', documentType: '', propertyType: '' }],
    //       }));
    // };

    // const handleAddImage = () => {
    //     // setFormData({ ...formData, images: [...formData.images, {}] });
    //     setFormData(prevState => ({
    //         ...prevState,
    //         images: [...prevState.images, { propertyImage: '' }],
    //         console.log('Images array after adding new image:', newImages);
    //       }));
    // };


    const handleAddDocument = () => {
        setFormData(prevState => {
        const newDocuments = [...prevState.documents, { documentImage: null, documentPdf: null, documentType: '', propertyType: selectedPropertyType }];
        console.log('Documents array after adding new document:', newDocuments);
        return {
            ...prevState,
            documents: newDocuments,
        };
        });
    };

    const handleAddImage = () => {
        setFormData(prevState => {
          const newImages = [...prevState.images, { propertyImage: null }];
          console.log('Images array after adding new image:', newImages);
          return {
            ...prevState,
            images: newImages,
          };
        });
      };

    // const handleDeleteDocument = (indexToDelete) => {
    //     setFormData(prevFormData => ({
    //       ...prevFormData,
    //       documents: prevFormData.documents.filter((_, index) => index !== indexToDelete)
    //     }));
    //   };

    const handleDeleteDocument = (index) => {
        setFormData(prevState => {
          const updatedDocuments = prevState.documents.filter((_, i) => i !== index);
          console.log('Updated documents array after delete:', updatedDocuments);
          return {
            ...prevState,
            documents: updatedDocuments,
          };
        });
      };

    // const handleDeleteImage = (indexToDelete) => {
    // setFormData(prevFormData => ({
    //     ...prevFormData,
    //     images: prevFormData.images.filter((_, index) => index !== indexToDelete)
    // }));
    // };
    const handleDeleteImage = (index) => {
        setFormData(prevState => {
          const updatedImages = prevState.images.filter((_, i) => i !== index);
          console.log('Updated images array after delete:', updatedImages);
          return {
            ...prevState,
            images: updatedImages,
          };
        });
      };
  
    const handleSubmit = (event) => {
      event.preventDefault();
      // Handle form submission
    };
    // Conditional rendering based on loading state
    if (loading) {
        return <div className='loading'>Loading...</div>;
    }
    return(
        (isAuthenticated ?
         isLoading ? <div className="loading-animation">Loading...</div>:
        <>
        <div className='AddItem'>
            <div className='details'>
                <div className='wrapper'>
                    <div className='formheader'>
                        <h1 className='h1'>Add Property</h1>
                        <div className="form-actions">
                            <button 
                                className='formsubmit' 
                                type="button" 
                                onClick={handleSubmitForm}
                                disabled={!isFormValid}
                                >
                                Save
                            </button>
                        </div>
                    </div>
                
                    {/* <div className='title'>
                        <h1>User information</h1>
                        <button> update Profile</button>
                    </div>
                    <div className='info'>
                        <span>
                            Avatar:
                            <img src='https://th.bing.com/th?q=Profile+Emoji&w=120&h=120&c=1&rs=1&qlt=90&cb=1&dpr=1.3&pid=InlineBlock&mkt=en-IN&cc=IN&setlang=en&adlt=moderate&t=1&mw=247' alt="" />
                        </span>
                         <span>UserName: <b>{user.username}</b></span>
                         <span> E-Mail: <b>{user.email}</b></span>
                        <button> update Profile</button>
                    </div> */}
                    {/* <div className='title'>
                        <h1>
                            My List
                        </h1>
                        <button> create new group</button>
                    </div> */}
                    {/* <div className="info">
                            <span>
                                Avatar:
                                <img src='https://th.bing.com/th?q=Profile+Emoji&w=120&h=120&c=1&rs=1&qlt=90&cb=1&dpr=1.3&pid=InlineBlock&mkt=en-IN&cc=IN&setlang=en&adlt=moderate&t=1&mw=247' alt="" />
                            </span>
                            <span>
                                Username: <b>{user.username}</b>
                            </span>
                            <span>
                                Email: <b>{user.email}</b>
                            </span>
                        </div> */}
                    <div className='title'>
                        {/* <AddItemForm user={user} access={access}/> */}
                        <div className="add-item-form">
                            <div className="tabs">
                                <button
                                className={`tab-button ${activeTab === 1 ? 'active' : ''}`}
                                onClick={() => handleTabClick(1)}
                                >
                                Basic Info
                                </button>
                                <button
                                className={`tab-button ${activeTab === 2 ? 'active' : ''}`}
                                onClick={() => handleTabClick(2)}
                                >
                                Location Details
                                </button>
                                <button
                                className={`tab-button ${activeTab === 3 ? 'active' : ''}`}
                                onClick={() => handleTabClick(3)}
                                >
                                Property Value
                                </button>
                                <button
                                className={`tab-button ${activeTab === 4 ? 'active' : ''}`}
                                onClick={() => handleTabClick(4)}
                                >
                                Property Area
                                </button>
                            </div>

                            <form id="propertyForm">
                                {activeTab === 1 && (
                                <div className="tab-content">
                                    <h2>Basic Info</h2>
                                    {/* Basic Info fields */}
                                    <label>Property Name</label>
                                    <div className={`form-group ${isValid.propertyName ? '' : 'has-danger'}`}>
                                        <input className={`form-control ${isValid.propertyName ? '' : 'is-invalid'}`} 
                                            type="text" name="propertyName" value={formData.propertyName} onChange={handleChange} required />
                                        {!isValid.propertyName && (
                                            <div className='invalid-feedback'>
                                                Property name must be at least 3 characters long.
                                            </div>
                                        )}
                                    </div>
                                    <label>Discription</label>
                                    <div className={`form-group ${isValid.discription ? '' : 'has-danger'}`}>
                                        <input className={`form-control ${isValid.discription? '' : 'is-invalid'}`} 
                                            type="text" name="discription" value={formData.discription} onChange={handleChange} required />
                                        {!isValid.discription && (
                                            <div className='invalid-feedback'>
                                                Discription name must be at least 3 characters long.
                                            </div>
                                        )}
                                    </div>
                                    <label>Plot No</label>
                                    <div className={`form-group ${isValid.plotNo ? '' : 'has-danger'}`}>
                                        <input className={`form-control ${isValid.plotNo ? '' : 'is-invalid'}`} 
                                            type="text" name="plotNo" value={formData.plotNo} onChange={handleChange} required />
                                        {!isValid.plotNo && (
                                            <div className='invalid-feedback'>
                                                Plot Number name must be at least 3 characters long.
                                            </div>
                                        )}
                                    </div>
                                    <label>Survey Number</label>
                                    <div className={`form-group ${isValid.surveyNumber ? '' : 'has-danger'}`}>
                                        <input className={`form-control ${isValid.surveyNumber ? '' : 'is-invalid'}`} 
                                            type="text" name="surveyNumber" value={formData.surveyNumber} onChange={handleChange} required />
                                        {!isValid.surveyNumber && (
                                            <div className='invalid-feedback'>
                                                Survey Number name must be Alphanumeric.
                                            </div>
                                        )}
                                    </div>
                                    <label>Patta Number</label>
                                    <div className={`form-group ${isValid.pattaNumber ? '' : 'has-danger'}`}>
                                        <input className={`form-control ${isValid.pattaNumber ? '' : 'is-invalid'}`} 
                                            type="text" name="pattaNumber" value={formData.pattaNumber} onChange={handleChange} required />
                                        {!isValid.pattaNumber && (
                                            <div className='invalid-feedback'>
                                                Patta Number name must be Alphanumeric.
                                            </div>
                                        )}
                                    </div>
                                    <label>Subdivision Number</label>
                                    <div className={`form-group ${isValid.subdivisionNumber ? '' : 'has-danger'}`}>
                                        <input className={`form-control ${isValid.subdivisionNumber ? '' : 'is-invalid'}`} 
                                            type="text" name="subdivisionNumber" value={formData.subdivisionNumber} onChange={handleChange} required />
                                        {!isValid.subdivisionNumber && (
                                            <div className='invalid-feedback'>
                                                Sub Division Number name must be Alphanumeric.
                                            </div>
                                        )}
                                    </div>
                                    <label>Building or Flat No</label>
                                    <div className={`form-group ${isValid.buildingOrFlatNo ? '' : 'has-danger'}`}>
                                        <input className={`form-control ${isValid.buildingOrFlatNo ? '' : 'is-invalid'}`} 
                                            type="text" name="buildingOrFlatNo" value={formData.buildingOrFlatNo} onChange={handleChange} required />
                                        {!isValid.buildingOrFlatNo && (
                                            <div className='invalid-feedback'>
                                                Building Or Flat Number name must be Alphanumeric.
                                            </div>
                                        )}
                                    </div>
                                    <label>Village</label>
                                    <div className={`form-group ${isValid.village ? '' : 'has-danger'}`}>
                                    <input className={`form-control ${isValid.village ? '' : 'is-invalid'}`} 
                                        type="text" name="village" value={formData.village} onChange={handleChange} required />
                                        {!isValid.village && (
                                            <div className='invalid-feedback'>
                                                village name must  be at least 3 characters long.
                                            </div>
                                        )}
                                    
                                    {/* <label>Property Type</label>
                                    <input type="text" name="propertyType" required /> */}
                                    </div>
                                </div>
                                )}

                                {activeTab === 2 && (
                                <div className="tab-content">
                                    <h2>Location Details</h2>
                                    {/* Location Details fields */}
                                    <div className='select-container'>
                                        <label>Country:</label>
                                        <div className={`form-group ${isValid.country ? '' : 'has-danger'}`}>
                                            <select className={`form-control ${isValid.country ? '' : 'is-invalid'}`}
                                                onChange={handleCountryChange} value={selectedCountry}>
                                                <option value="">Select Country</option>
                                                {locations.map(country => (
                                                    <option key={country.id} value={country.id}>
                                                        {country.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {!isValid.country && (
                                                <div className="invalid-feedback">
                                                    Please select a country.
                                                </div>
                                            )}
                                        </div>
                                        <label>State:</label>
                                        <div className={`form-group ${isValid.state ? '' : 'has-danger'}`}>
                                        <select className={`form-control ${isValid.state ? '' : 'is-invalid'}`}
                                            onChange={handleStateChange} value={selectedState} disabled={!selectedCountry}>
                                            <option value="">Select State</option>
                                            {states.map(state => (
                                                <option key={state.id} value={state.id}>
                                                    {state.name}
                                                </option>
                                            ))}
                                        </select>
                                        {!isValid.state && (
                                            <div className="invalid-feedback">
                                                Please select a state.
                                            </div>
                                        )}
                                        </div>
                                        <label>City:</label>
                                        <div className={`form-group ${isValid.country ? '' : 'has-danger'}`}>
                                        {/* <select disabled={!selectedState} value={selectedcity} onChange={(e) => setSelectedcity(e.target.value)}> */}
                                        <select className={`form-control ${isValid.country ? '' : 'is-invalid'}`}
                                            disabled={!selectedState} value={selectedcity} onChange={handleCityChange}>
                                            <option value="">Select City</option>
                                            {cities.map(city => (
                                                <option key={city.id} value={city.id}>
                                                    {city.name}
                                                </option>
                                            ))}
                                        </select>
                                        {!isValid.country && (
                                            <div className="invalid-feedback">
                                                Please select a country.
                                            </div>
                                        )}
                                        </div>
                                    </div>
                                    <label>Address 1</label>
                                    <div className={`form-group ${isValid.address1 ? '' : 'has-danger'}`}>
                                        <input className={`form-control ${isValid.address1 ? '' : 'is-invalid'}`} 
                                            type="text" name="address1" value={formData.address1} onChange={handleChange} required />
                                        {!isValid.address1 && (
                                            <div className='invalid-feedback'>
                                                Address must be at least 3 characters long.
                                            </div>
                                        )}
                                    </div>
                                    <label>Address 2</label>
                                    <div className={`form-group ${isValid.address2 ? '' : 'has-danger'}`}>
                                        <input className={`form-control ${isValid.address2 ? '' : 'is-invalid'}`} 
                                            type="text" name="address2" value={formData.address2} onChange={handleChange} />
                                        {!isValid.address2 && (
                                            <div className='invalid-feedback'>
                                                Address must be at least 3 characters long.
                                            </div>
                                        )}
                                    </div>
                                    <label>Pincode</label>
                                    <div className={`form-group ${isValid.pincode ? '' : 'has-danger'}`}>
                                        <input className={`form-control ${isValid.pincode ? '' : 'is-invalid'}`} 
                                            type="text" name="pincode" value={formData.pincode} onChange={handleChange} required />
                                        {!isValid.pincode && (
                                            <div className='invalid-feedback'>
                                                Pincode must be 6 characters long.
                                            </div>
                                        )}
                                    </div>
                                    <label>Locality</label>
                                    <div className={`form-group ${isValid.locality ? '' : 'has-danger'}`}>
                                        <input className={`form-control ${isValid.locality ? '' : 'is-invalid'}`} 
                                            type="text" name="locality" value={formData.locality} onChange={handleChange}/>
                                        {!isValid.locality && (
                                            <div className='invalid-feedback'>
                                                Locality must be 3 characters long.
                                            </div>
                                        )}
                                    </div>
                                    <label>Street</label>
                                    <div className={`form-group ${isValid.street ? '' : 'has-danger'}`}>
                                        <input className={`form-control ${isValid.street ? '' : 'is-invalid'}`} 
                                            type="text" name="street" value={formData.street} onChange={handleChange}/>
                                        {!isValid.street && (
                                            <div className='invalid-feedback'>
                                                Street must be 3 characters long.
                                            </div>
                                        )}
                                    </div>
                                    <label>Latitude</label>
                                    <div className={`form-group ${isValid.latitude ? '' : 'has-danger'}`}>
                                        <input className={`form-control ${isValid.latitude ? '' : 'is-invalid'}`} 
                                            type="text" name="latitude" value={formData.latitude} onChange={handleChange}/>
                                        {!isValid.latitude && (
                                            <div className='invalid-feedback'>
                                                Coordinates should be in proper format.
                                            </div>
                                        )}
                                    </div>
                                    <label>Longitude</label>
                                    <div className={`form-group ${isValid.longitude ? '' : 'has-danger'}`}>
                                        <input className={`form-control ${isValid.longitude ? '' : 'is-invalid'}`} 
                                            type="text" name="longitude" value={formData.longitude} onChange={handleChange}/>
                                        {!isValid.longitude && (
                                            <div className='invalid-feedback'>
                                                Coordinates should be in proper format.
                                            </div>
                                        )}
                                    </div>
                                </div>
                                )}

                                {activeTab === 3 && (
                                <div className="tab-content">
                                    <h2>Property Value</h2>
                                    {/* Property Value fields */}
                                    <label>Purchase Value</label>
                                    <div className={`form-group ${isValid.purchaseValue ? '' : 'has-danger'}`}>
                                        <input className={`form-control ${isValid.purchaseValue ? '' : 'is-invalid'}`} 
                                            type="text" name="purchaseValue" value={formData.purchaseValue} onChange={handleChange} required />
                                        {!isValid.purchaseValue && (
                                            <div className='invalid-feedback'>
                                                value should be numeric with two decimal (sample:100.00)
                                            </div>
                                        )}
                                    </div>
                                    <label>Purchase Date</label>
                                    <div className={`form-group ${isValid.purchaseDate ? '' : 'has-danger'}`}>
                                        <input className={`form-control ${isValid.purchaseDate ? '' : 'is-invalid'}`} 
                                            type="date" name="purchaseDate" value={formData.purchaseDate} onChange={handleChange} required />
                                        {!isValid.purchaseDate && (
                                            <div className='invalid-feedback'>
                                                value should be date (YYYY-MM-DD format)
                                            </div>
                                        )}
                                    </div>
                                    <label>Guideline Value</label>
                                    <div className={`form-group ${isValid.guidelineValue ? '' : 'has-danger'}`}>
                                        <input  className={`form-control ${isValid.guidelineValue ? '' : 'is-invalid'}`} 
                                            type="text" name="guidelineValue" value={formData.guidelineValue} onChange={handleChange} required />
                                        {!isValid.guidelineValue && (
                                            <div className='invalid-feedback'>
                                                value should be numeric with two decimal (sample:100.00)
                                            </div>
                                        )}
                                    </div>
                                    <label>Market Value</label>
                                    <div className={`form-group ${isValid.marketValue ? '' : 'has-danger'}`}>
                                        <input className={`form-control ${isValid.marketValue ? '' : 'is-invalid'}`} 
                                            type="text" name="marketValue" value={formData.marketValue} onChange={handleChange} required />
                                        {!isValid.marketValue && (
                                            <div className='invalid-feedback'>
                                                value should be numeric with two decimal (sample:100.00)
                                            </div>
                                        )}
                                    </div>
                                    <label>Rental Value</label>
                                    <div className={`form-group ${isValid.rentalValue? '' : 'has-danger'}`}>
                                        <input className={`form-control ${isValid.rentalValue ? '' : 'is-invalid'}`} 
                                            type="text" name="rentalValue" value={formData.rentalValue} onChange={handleChange} required />
                                        {!isValid.rentalValue && (
                                            <div className='invalid-feedback'>
                                                value should be numeric with two decimal (sample:100.00)
                                            </div>
                                        )}
                                    </div>
                                    <label>Age</label>
                                    <div className={`form-group ${isValid.age? '' : 'has-danger'}`}>
                                        <input className={`form-control ${isValid.age ? '' : 'is-invalid'}`} 
                                            type="text" name="age" value={formData.age} onChange={handleChange} required />
                                        {!isValid.age && (
                                            <div className='invalid-feedback'>
                                                value should be numeric.
                                            </div>
                                        )}
                                    </div>
                                    <h3>Taxes</h3>
                                    <label>Property Tax Number</label>
                                    <div className={`form-group ${isValid.propertyTaxNumber? '' : 'has-danger'}`}>
                                        <input className={`form-control ${isValid.propertyTaxNumber ? '' : 'is-invalid'}`} 
                                            type="text" name="propertyTaxNumber" value={formData.propertyTaxNumber} onChange={handleChange} required />
                                        {!isValid.propertyTaxNumber && (
                                            <div className='invalid-feedback'>
                                                value should be alphanumeric.
                                            </div>
                                        )}
                                    </div>
                                    <label>Property Tax</label>
                                    <div className={`form-group ${isValid.propertyTax? '' : 'has-danger'}`}>
                                        <input className={`form-control ${isValid.propertyTax ? '' : 'is-invalid'}`} 
                                            type="text" name="propertyTax" value={formData.propertyTax} onChange={handleChange} required />
                                        {!isValid.propertyTax && (
                                            <div className='invalid-feedback'>
                                                value should be numeric with two decimal (sample:100.00)
                                            </div>
                                        )}
                                    </div>
                                    <label>Water Tax</label>
                                    <div className={`form-group ${isValid.waterTax? '' : 'has-danger'}`}>
                                        <input className={`form-control ${isValid.waterTax ? '' : 'is-invalid'}`} 
                                            type="text" name="waterTax" value={formData.waterTax} onChange={handleChange} required />
                                        {!isValid.waterTax && (
                                            <div className='invalid-feedback'>
                                                value should be numeric with two decimal (sample:100.00)
                                            </div>
                                        )}
                                    </div>
                                    <label>Water Tax Number</label>
                                    <div className={`form-group ${isValid.waterTaxNumber ? '' : 'has-danger'}`}>
                                        <input className={`form-control ${isValid.waterTaxNumber ? '' : 'is-invalid'}`} 
                                            type="text" name="waterTaxNumber" value={formData.waterTaxNumber} onChange={handleChange} required />
                                        {!isValid.waterTaxNumber && (
                                            <div className='invalid-feedback'>
                                                value should be alphanumeric.
                                            </div>
                                        )}
                                    </div>
                                    <label>Property Insurance</label>
                                    <div className={`form-group ${isValid.propertyInsurance ? '' : 'has-danger'}`}>
                                        <input className={`form-control ${isValid.propertyInsurance ? '' : 'is-invalid'}`} 
                                            type="text" name="propertyInsurance" value={formData.propertyInsurance} onChange={handleChange} required />
                                        {!isValid.propertyInsurance && (
                                            <div className='invalid-feedback'>
                                                value should be alphanumeric.
                                            </div>
                                        )}
                                    </div>
                                    <label>Insurance Expiry Date</label>
                                    <div className={`form-group ${isValid.insuranceExpiryDate ? '' : 'has-danger'}`}>
                                        <input className={`form-control ${isValid.insuranceExpiryDate ? '' : 'is-invalid'}`} 
                                            type="date" name="insuranceExpiryDate" value={formData.insuranceExpiryDate} onChange={handleChange} required />
                                        {!isValid.insuranceExpiryDate && (
                                            <div className='invalid-feedback'>
                                                value should be date (YYYY-MM-DD format)
                                            </div>
                                        )}
                                    </div>
                                </div>
                                )}

                                {activeTab === 4 && (
                                <div className="tab-content">
                                    <h2>Property Area</h2>
                                    {/* Property Area fields */}
                                    <div className='select-container'>
                                        <label>Property Type:</label>
                                        <div className={`form-group ${isValid.propertyType ? '' : 'has-danger'}`}>
                                            <select
                                                className={`form-control ${isValid.propertyType ? '' : 'is-invalid'}`}
                                                value={selectedPropertyType}
                                                onChange={handlePropertyTypeChange}
                                            >
                                                <option value="">Select Property Type</option>
                                                {types.property_types.map((type) => (
                                                    <option key={type.id} value={type.id}>
                                                        {type.type_name}
                                                    </option>
                                                ))}
                                            </select>
                                            {!isValid.propertyType && (
                                                <div className="invalid-feedback">
                                                    Please select a property type.
                                                </div>
                                            )}
                                        </div>
                                        {/* <label>Apartment Name</label>
                                        <input type="text" name="apartmentName" required /> */}
                                        <label>BHK Type:</label>
                                        <div className={`form-group ${isValid.bhkType ? '' : 'has-danger'}`}>
                                            <select
                                                className={`form-control ${isValid.bhkType ? '' : 'is-invalid'}`}
                                                value={selectedBHKType}
                                                onChange={handleBHKTypeChange}
                                            >
                                                <option value="">Select BHK Type</option>
                                                {types.bhk_types.map((type) => (
                                                    <option key={type.id} value={type.id}>
                                                        {type.bhk_type}
                                                    </option>
                                                ))}
                                            </select>
                                            {!isValid.bhkType && (
                                                <div className="invalid-feedback">
                                                    Please select a BHK type.
                                                </div>
                                            )}
                                        </div>
                                        <label>Ownership Type:</label>
                                        <div className={`form-group ${isValid.ownershipType ? '' : 'has-danger'}`}>
                                            <select
                                                className={`form-control ${isValid.ownershipType ? '' : 'is-invalid'}`}
                                                value={selectedOwnershipType}
                                                onChange={handleOwnershipTypeChange}
                                            >
                                                <option value="">Select Ownership Type</option>
                                                {types.ownership_types.map((type) => (
                                                    <option key={type.id} value={type.id}>
                                                        {type.ownership_type}
                                                    </option>
                                                ))}
                                            </select>
                                            {!isValid.ownershipType && (
                                                <div className="invalid-feedback">
                                                    Please select an ownership type.
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <label>Apartment Name</label>
                                    <div className={`form-group ${isValid.apartmentName ? '' : 'has-danger'}`}>
                                        <input className={`form-control ${isValid.apartmentName ? '' : 'is-invalid'}`} 
                                            type="text" name="apartmentName"  value={formData.apartmentName} onChange={handleChange} required />
                                        {!isValid.apartmentName && (
                                            <div className='invalid-feedback'>
                                                input should be atleast 3 char long
                                            </div>
                                        )}
                                    </div>
                                    <label>Built-up Area</label>
                                    <div className={`form-group ${isValid.builtUpArea ? '' : 'has-danger'}`}>
                                        <input className={`form-control ${isValid.builtUpArea ? '' : 'is-invalid'}`} 
                                            type="text" name="builtUpArea" value={formData.builtUpArea} onChange={handleChange} required />
                                        {!isValid.builtUpArea && (
                                            <div className='invalid-feedback'>
                                                value should be numeric with two decimal (sample:100.00)
                                            </div>
                                        )}
                                    </div>
                                    <label>Property Area</label>
                                    <div className={`form-group ${isValid.propertyArea ? '' : 'has-danger'}`}>
                                        <input className={`form-control ${isValid.propertyArea? '' : 'is-invalid'}`} 
                                            type="text" name="propertyArea" value={formData.propertyArea} onChange={handleChange} required />
                                        {!isValid.propertyArea && (
                                            <div className='invalid-feedback'>
                                                value should be numeric with two decimal (sample:100.00)
                                            </div>
                                        )}
                                    </div>
                                    <label>Carpet Area</label>
                                    <div className={`form-group ${isValid.carpetArea ? '' : 'has-danger'}`}>
                                        <input className={`form-control ${isValid.carpetArea? '' : 'is-invalid'}`} 
                                            type="text" name="carpetArea" value={formData.carpetArea} onChange={handleChange} required />
                                        {!isValid.carpetArea && (
                                            <div className='invalid-feedback'>
                                                value should be numeric with two decimal (sample:100.00)
                                            </div>
                                        )}
                                    </div>
                                    <label>Property Age</label>
                                    <div className={`form-group ${isValid.propertyAge ? '' : 'has-danger'}`}>
                                        <input className={`form-control ${isValid.propertyAge? '' : 'is-invalid'}`} 
                                            type="text" name="propertyAge" value={formData.propertyAge} onChange={handleChange} required />
                                        {!isValid.propertyAge && (
                                            <div className='invalid-feedback'>
                                                value should be numeric.
                                            </div>
                                        )}
                                    </div>
                                    <label>Facing</label>
                                    <div className={`form-group ${isValid.facing ? '' : 'has-danger'}`}>
                                        <input className={`form-control ${isValid.facing ? '' : 'is-invalid'}`} 
                                            type="text" name="facing" value={formData.facing} onChange={handleChange} required />
                                        {!isValid.facing && (
                                            <div className='invalid-feedback'>
                                                input should be atleast 3 char long
                                            </div>
                                        )}
                                    </div>
                                    <label>Floor Type</label>
                                    <div className={`form-group ${isValid.floorType ? '' : 'has-danger'}`}>
                                        <input className={`form-control ${isValid.floorType ? '' : 'is-invalid'}`} 
                                            type="text" name="floorType" value={formData.floorType} onChange={handleChange} required />
                                        {!isValid.floorType && (
                                            <div className='invalid-feedback'>
                                                input should be atleast 3 char long
                                            </div>
                                        )}
                                    </div>
                                    <label>Floor</label>
                                    <div className={`form-group ${isValid.floor ? '' : 'has-danger'}`}>
                                        <input className={`form-control ${isValid.floor ? '' : 'is-invalid'}`} 
                                            type="text" name="floor" value={formData.floor} onChange={handleChange} required />
                                        {!isValid.floor && (
                                            <div className='invalid-feedback'>
                                                input should be atleast 3 char long
                                            </div>
                                        )}
                                    </div>
                                    <label>Total Floors</label>
                                    <div className={`form-group ${isValid.totalFloors ? '' : 'has-danger'}`}>
                                        <input className={`form-control ${isValid.totalFloors ? '' : 'is-invalid'}`} 
                                            type="text" name="totalFloors" value={formData.totalFloors} onChange={handleChange} required />
                                        {!isValid.totalFloors && (
                                            <div className='invalid-feedback'>
                                                value should be numeric.
                                            </div>
                                        )}
                                    </div>
                                    <label>Furnishing</label>
                                    <div className={`form-group ${isValid.furnishing ? '' : 'has-danger'}`}>
                                        <input className={`form-control ${isValid.furnishing ? '' : 'is-invalid'}`} 
                                            type="text" name="furnishing" value={formData.furnishing} onChange={handleChange} required />
                                        {!isValid.furnishing && (
                                            <div className='invalid-feedback'>
                                                input should be atleast 3 char long
                                            </div>
                                        )}
                                    </div>
                                    <label>Parking</label>
                                    <div className={`form-group ${isValid.parking ? '' : 'has-danger'}`}>
                                        <input className={`form-control ${isValid.parking ? '' : 'is-invalid'}`} 
                                            type="text" name="parking" value={formData.parking} onChange={handleChange} required />
                                        {!isValid.parking && (
                                            <div className='invalid-feedback'>
                                                input should be atleast 3 char long
                                            </div>
                                        )}
                                    </div>
                                    <label>Kitchen Type</label>
                                    <div className={`form-group ${isValid.kitchenType ? '' : 'has-danger'}`}>
                                        <input className={`form-control ${isValid.kitchenType ? '' : 'is-invalid'}`} 
                                            type="text" name="kitchenType"  value={formData.kitchenType} onChange={handleChange} required />
                                        {!isValid.kitchenType && (
                                            <div className='invalid-feedback'>
                                                input should be atleast 3 char long
                                            </div>
                                        )}
                                    </div>
                                    <label>Maintenance Cost</label>
                                    <div className={`form-group ${isValid.maintenanceCost ? '' : 'has-danger'}`}>
                                    <input className={`form-control ${isValid.maintenanceCost ? '' : 'is-invalid'}`} 
                                        type="text" name="maintenanceCost" value={formData.maintenanceCost} onChange={handleChange} required />
                                        {!isValid.kitchenType && (
                                            <div className='invalid-feedback'>
                                                input should be atleast 3 char long
                                            </div>
                                        )}
                                    </div>
                                </div>
                                )}

                                {/* <div className="form-actions">
                                <button type="submit">Save</button>
                                </div> */}
                            </form>
                            </div>
                        {/* <button className='b1'>Add to List</button> */}
                        {/* <button className='b1' onClick={() => setAddItemOpen(true)}>Add to List</button> */}

                        {/* {isAddItemOpen && (
                            <AddItemForm
                                user={user} 
                                onClose={() => setAddItemOpen(false)} 
                                onUpdate={handleSubmitItem} 
                            />
                        )} */}

                    </div>
                    {/* <List/>
                    <div className='title'>
                        <h1>saved List</h1>
                    </div>
                    <List/> */}
                    {/* <div className='info'>
                        <span>Avatar:
                            <img src='' alt=''/>
                         </span>
                         <span>UserName: <b>John Doe</b></span>
                         <span> E-Mail: <b>John@gmail.com</b></span>
                        <button> update Profile</button>
                    </div> */}
                    {/* <div className='title'>
                        <h1>
                            My List
                        </h1>
                        <button> create new group</button>
                    </div> */}
                    {/* <div className='title'>
                        <h1>
                            saved list
                        </h1>
                    </div> */}
                </div>
                </div>
            <div className='chatcontainer'>
                <div className='wrapper' >
                <div className='title'>
                        <h1 className='chath1'>Attachments</h1>
                        {/* <button className='chatb1'> update Profile</button> */}
                        {/* <button className='chatb1' onClick={() => setIsFormOpen(true)}>Update Profile</button>

                        {isFormOpen && (
                            <ProfileUpdateForm 
                                user={user} 
                                onClose={() => setIsFormOpen(false)} 
                                onUpdate={handleUpdate} 
                            />
                        )} */}

                        {/* Other content for the UserDashboard */}
                    </div>
                    <div className='info'>
                        <>
                        <div>
                            <div className='documents'>
                                <h3>Documents</h3>
                                <button className='documentsbutton' type="button" onClick={handleAddDocument}>Add Document</button>
                            </div>
                            {formData.documents.map((doc, index) => (
                            <DocumentInput
                                key={index}
                                document={doc}
                                onChange={(updatedDoc) => handleDocumentChange(index, updatedDoc)}
                                propertyType={selectedPropertyType}
                                documentTypes={types.document_types}
                                onDelete={() => handleDeleteDocument(index)}
                            />
                            ))}
                        </div>

                        <div>
                            <div className='images'>
                                <h3>Images</h3>
                                <button className='imagesbutton' type="button" onClick={handleAddImage}>Add Image</button>
                            </div>
                            
                            {formData.images.map((img, index) => (
                            <ImageInput
                                key={index}
                                image={img}
                                onChange={(updatedImage) => handleImageChange(index, updatedImage)}
                                onDelete={() => handleDeleteImage(index)}
                            />
                            ))}
                           
                        </div>
                        </>
                        {/* <span>
                            Avatar:
                            <img src='https://th.bing.com/th?q=Profile+Emoji&w=120&h=120&c=1&rs=1&qlt=90&cb=1&dpr=1.3&pid=InlineBlock&mkt=en-IN&cc=IN&setlang=en&adlt=moderate&t=1&mw=247' alt="" />
                        </span>
                         <span>UserName: <b>{user.username}</b></span>
                         <span> E-Mail: <b>{user.email}</b></span> */}
                        {/* <button> update Profile</button> */}
                    </div>
                    {/* <div className="form-actions">
                        <button type="button" onClick={handleSubmitForm}>Save</button>
                    </div> */}
                </div>
                {/* <img src='/static/home3.png' alt=''/> */}
            </div>
        </div>
        </> : null)
        
    );
        
    //     isAuthenticated ? (
    //         <div style={{
    //             backgroundColor: '#f8f9fa',
    //             padding: '2rem',
    //             marginBottom: '2rem',
    //             borderRadius: '0.3rem',
    //             boxShadow: '0 0.2rem 0.5rem rgba(0, 0, 0, 0.1)'
    //         }}>
    //             <h1 style={{ fontSize: '2.5rem', marginBottom: '1rem' }}>Welcome to Our Website</h1>
    //         </div>
    //     ) :   <div style={{
    //         backgroundColor: '#f8f9fa',
    //         padding: '2rem',
    //         marginBottom: '2rem',
    //         borderRadius: '0.3rem',
    //         boxShadow: '0 0.2rem 0.5rem rgba(0, 0, 0, 0.1)'
    //     }}>
    //         <h1 style={{ fontSize: '2.5rem', marginBottom: '1rem' }}>Welcome to Our Website</h1>
    //         <p style={{ fontSize: '1.2rem', lineHeight: '1.6', marginBottom: '1rem' }}>
    //             This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.
    //         </p>
    //         <p>
    //             <a href="/login" className="btn btn-primary btn-lg">LOGIN</a>
    //         </p>
    //         {isError !== null && (
    //             <div className="error-message">
    //                 {Array.isArray(isError) && isError.map((error, index) => (
    //                     <p key={index}>{error}</p>
    //                 ))}
    //                 <button onClick={handleClearError}>Dismiss</button>
    //             </div>
    //         )}
    //     </div>
    
 };

 const mapStateToProps = (state) =>({
    isAuthenticated:state.auth.isAuthenticated,
    access:state.auth.access,
    isError: state.error.errorMessage,
    user: state.auth.user,
    loading: state.auth.loading
});

const mapDispatchToProps = (dispatch) => ({
    updateUser: (data) => dispatch({ type: UPDATE_USER, payload: data }),
    clearError: () => dispatch(clearError()),
});

export default connect(mapStateToProps,mapDispatchToProps)(AddListItem);
// export default UserDashboard;