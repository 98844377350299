// reducers.js

import { API_REQUEST_SUCCESS, CLEAR_NOTIFICATION } from '../actions/Types';

const initialState = {
  notification: null
};

export default function (state = initialState, action){
  switch (action.type) {
    case API_REQUEST_SUCCESS:
      return {
        ...state,
        notification: action.payload.message
      };
    case CLEAR_NOTIFICATION:
      return {
        ...state,
        notification: null
      };
    default:
      return state;
  }
};

