import React, { useState, useEffect } from 'react';
import { Slider } from '../../components/slider/Slider';
import { singlePostData } from '../../lib/dummydata';
import { Map } from '../../components/map/Map';
import './singlepage.scss'
import { useLocation, useNavigate } from 'react-router-dom';
import { baseURL } from '../../config';

export const SInglePage2 = () => {
    const location = useLocation();
    const navigate = useNavigate(); 
    const {propertyData, token} = location.state || {};
    console.log(propertyData)
    
    console.log(token)
    const property_id = propertyData.property_id || '';
    console.log(property_id)

    const [fetchedData, setFetchedData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (property_id && token) {
          fetch(`${baseURL}/api/property/${property_id}/`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `JWT ${token}`, // Ensure token is correctly prefixed
            },
          })
          .then(response => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
          })
          .then(data => {
            setFetchedData(data);
            setLoading(false);
          })
          .catch(err => {
            console.error("Fetch error: ", err);
            setError(err);
            setLoading(false);
          });
        } else {
          setLoading(false);
        }
      }, [property_id, token]);


    const handleDelete = () => {
      if (window.confirm('Are you sure you want to delete this property?')) {
        fetch(`${baseURL}/api/property/${property_id}/`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${token}`, // Ensure token is correctly prefixed
          },
        })
          .then(response => {
            if (response.ok) {
              alert('Property deleted successfully');
              navigate('/user_dashboard'); // Redirect to properties list after successful deletion
            } else {
              throw new Error('Failed to delete the property.');
            }
          })
          .catch(err => {
            console.error('Delete error: ', err);
            alert('An error occurred while deleting the property.');
          });
      }
    };

    // Function to handle navigation to the EditList component
    const handleEdit = () => {
      navigate('/edit-listitem', { state: { propertyData: fetchedData } }); // Pass the property data if needed
    };

    const {
        property_name,
        address1,
        market_value,
        bhk_type,
        built_up_area,
        maintenance_cost,
        images = [],
        property_type = '',
    } = fetchedData || {};



    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;
    if (!fetchedData) return <div>No data available</div>;

  return (
    <div className='singlepage'>
      <div className='details'>
        <div className="wrapper">
          <Slider images={images}/>
          <div className="info">
            <div className="top">
              <div className="post">
                <h1>{singlePostData.title}</h1>
                <div className="address">
                  <img src='/pin.png' alt=''/>
                  <span>{address1}</span>
                </div>
                <div className="price">$ {market_value}</div>
              </div>
              <div className="user">
                {/* <img src={userData.img} alt=''/> */}
                <div>
                  {/* <p>User: {username}</p>
                  <p>Email: {userinfo.email}</p> */}
                  {/* Render other user information here */}
              </div>
              </div>
            </div>
            <div className="bottom">
              {property_type}
            </div>
          </div>
        </div>
      </div>
      <div className='features'>
        <div className="wrapper">
          <div className="delete_edit">
            <p className='title'>General</p>
            <button className="delete-btn" onClick={handleDelete}>
                Delete
            </button>
            <button className="edit-btn" onClick={handleEdit}>
                Edit
            </button>
          </div>
          <div className="listvertical">
            <div className="feature">
              <img src="/utility.png" alt="" />
              <div className="featuretext">
                <span>Utilities</span>
                <p>Renter is Responsible</p>
              </div>
            </div>
            <div className="feature">
              <img src="/pet.png" alt="" />
              <div className="featuretext">
                <span>Pet Policy</span>
                <p>Pets Allowed</p>
              </div>
            </div>
            <div className="feature">
              <img src="/fee.png" alt="" />
              <div className="featuretext">
                <span>Maintenance Fees</span>
                <p>{maintenance_cost}</p>
              </div>
            </div>
          </div>
          <p className='title'>Sizes</p>
          <div className='sizes'>
            <div className="size">
              <img src="/size.png" alt="" />
              <span>80 sqft</span>
            </div>
            <div className="size">
              <img src="/bed.png" alt="" />
              <span>2 Beds</span>
            </div>
            <div className="size">
              <img src="/bath.png" alt="" />
              <span>2 Baths</span>
            </div>
          </div>
          <p className='title'>Nearby Places</p>
          <div className="listhorizontal">
            <div className="feature">
              <img src="/school.png" alt="" />
              <div className="featureText">
                <span>School</span>
                <p>250m away</p>
              </div>
            </div>
            <div className="feature">
              <img src="/pet.png" alt="" />
              <div className="featureText">
                <span>Bus Stop</span>
                <p>100m away</p>
              </div>
            </div>
            <div className="feature">
              <img src="/fee.png" alt="" />
              <div className="featureText">
                <span>Restaurant</span>
                <p>200m away</p>
              </div>
            </div>
          </div>
          <p className='title'>Location</p>
          <div className="mapContainer">
            <Map items={[singlePostData]}/>
          </div>
          <div className="buttons">
            <button>
              <img src="/chat.png" alt="" />
              Send a Message
            </button>
            <button>
              <img src="/save.png" alt="" />
              Save the Place
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
