import React from 'react'
import "./filter.scss"

export const Filter = () => {
  return (
    <div className='filter'>
        <h1>Search results for <b>Kerala</b></h1>
        <div className="top">
            <div className="item">
                <label htmlFor="city">Location</label>
                <input 
                    type="text" 
                    id='city' 
                    name='city' 
                    placeholder='City/Location'
                />
            </div>
        </div>
        <div className="bottom">
        <div className="item">
                <label htmlFor="type">Type</label>
                <select name="type" id="type">
                    <option value="">any</option>
                    <option value="buy">Buy</option>
                    <option value="rent">Rent</option>
                </select> 
            </div>
            <div className="item">
                <label htmlFor="property">Property</label>
                <select name="property" id="property">
                    <option value="apartment">Apartment</option>
                    <option value="house">House</option>
                    <option value="condo">Condo</option>
                    <option value="land">Land</option>
                </select>
            </div>
            <div className="item">
                <label htmlFor="minprice">Min Price</label>
                <input 
                    type="number" 
                    id='minprice' 
                    name='minprice' 
                    placeholder='any'
                />
            </div>
            <div className="item">
                <label htmlFor="maxprice">Max Price</label>
                <input 
                    type="number" 
                    id='maxprice' 
                    name='maxprice' 
                    placeholder='any'
                />
            </div>
            <div className="item">
                <label htmlFor="bedrooms">Bedrooms</label>
                <input 
                    type="number" 
                    id='bedrooms' 
                    name='bedrooms' 
                    placeholder='any'
                />
            </div>
            <button>
                <img src="../search.png" alt="" />
            </button>
        </div>
    </div>
  )
}
