import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOAD_USER_SUCCESS,
    LOAD_USER_FAIL,
    SIGNUP_SUCCESS,
    SIGNUP_FAIL,
    ACTIVATION_SUCCESS,
    ACTIVATION_FAIL,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAIL,
    PASSWORD_RESET_CONFIRM_SUCCESS,
    PASSWORD_RESET_CONFIRM_FAIL,
    LOGOUT,
    GOOGLE_AUTH_SUCCESS,
    GOOGLE_AUTH_FAIL,
    UPDATE_USER,
    UPDATE_USER_FAIL,

} from './Types';
import axios from 'axios';
import { baseURL } from '../config';
import { setError } from './Error';
import {apiRequestSuccess} from './Notifications';


export const checkAuthenticated = () => async (dispatch) => {
    if (localStorage.getItem('access')) {
        const config ={
            headers:{
                'Content-Type':'application/json',
                'Accept':'application/json'
            }
        };

        const body = JSON.stringify({token: localStorage.getItem('access') });

        try {
            const res = await axios.post(`${baseURL}/auth/jwt/verify/`,body,config)
            if (res.data.code !== 'token_not_valid'){
                dispatch({
                    type:AUTHENTICATED_SUCCESS
                })
            } else {
                dispatch({
                    type:AUTHENTICATED_FAIL
                })
            }
        } catch (err) {
            dispatch({
                type:AUTHENTICATED_FAIL
            })
        }
    } else {
        dispatch({
            type:AUTHENTICATED_FAIL
        })
    }
};

export const singup = (email,username,mobile,first_name,last_name,password,re_password) => async (dispatch) => {
    console.log(baseURL,'login url 22')
    const config = {
        headers:{
            'Content-Type':'application/json'
        }
    };
    const body = JSON.stringify({email,username,mobile,first_name,last_name,password,re_password});

    try {
        console.log(baseURL)
        const res = await axios.post(`${baseURL}/auth/users/`,body, config);
        console.log(res)
        dispatch({
            type:SIGNUP_SUCCESS,
            payload:res.data
        });
        dispatch(apiRequestSuccess("Activation Mail Sent"));
    } catch (err) {
        console.log(err.response.data.mobile,"catch")
        dispatch({
            type:SIGNUP_FAIL
        })
        if (err.response) {
            const errorMessage = err.response.data;
            dispatch(setError(errorMessage)); // Dispatch setError action
        } else {
            dispatch(setError('Signup failed. Please try again later.'));
        }
        // throw err; // Optionally rethrow the error for component handling
    }
    
};

export const verify = (uid,token) => async (dispatch) =>{
    const config = {
        headers:{
            'Content-Type':'application/json'
        }
    };
    const body = JSON.stringify({uid,token});

    try {
        console.log(baseURL)
        const res = await axios.post(`${baseURL}/auth/users/activation/`,body, config);
        console.log(res)
        dispatch({
            type:ACTIVATION_SUCCESS,
        });
        dispatch(apiRequestSuccess("Verification Successfull"));
    } catch (err) {
        console.log("catch")
        dispatch({
            type:ACTIVATION_FAIL
        });
        if (err.response) {
            const errorMessage = err.response.data;
            dispatch(setError(errorMessage)); // Dispatch setError action
        } else {
            dispatch(setError('Signup failed. Please try again later.'));
        }
    }
}

export const login = (email,password) => async (dispatch) => {
    console.log(baseURL,'login url new')
    const config = {
        headers:{
            'Content-Type':'application/json'
        }
    };
    const body = JSON.stringify({email,password});

    try {
        console.log(baseURL,"from the config")
        const res = await axios.post(`${baseURL}/auth/jwt/create/`,body, config);
        console.log(res)
        dispatch({
            type:LOGIN_SUCCESS,
            payload:res.data
        });

        dispatch(load_user());

        // dispatch(apiRequestSuccess("Login Successfull"));
    } catch (err) {
        console.log("catch")
        dispatch({
            type:LOGIN_FAIL
        });
        if (err.response) {
            const errorMessage = err.response.data;
            dispatch(setError(errorMessage)); // Dispatch setError action
        } else {
            dispatch(setError('Login failed. Please Check the credentials.'));
        }
    }
};

export const load_user = () =>async (dispatch) =>{
    console.log('graph data')
    if (localStorage.getItem('access')) {
        console.log(localStorage.getItem('access'))
        const config={
            headers: {
                'Content-Type':'application/json',
                'Authorization':`JWT ${localStorage.getItem('access')}`,
                'Accept':'application/json'
            }
        };

        try {
            const res = await axios.get(`${baseURL}/auth/users/me/`, config);
            dispatch({
                type:LOAD_USER_SUCCESS,
                payload:res.data
            })
        } catch (err) {
            console.log(err)
            dispatch({
                type:LOAD_USER_FAIL,
            })
        }
    } else {
        dispatch({
            type:LOAD_USER_FAIL
        });
    }
}

export const reset_password = (email) => async(dispatch) =>{
    const config = {
        headers:{
            'Content-Type':'application/json'
        }
    };

    const body = JSON.stringify({email});

    try{
        await axios.post(`${baseURL}/auth/users/reset_password/`,body,config);
        dispatch({
            type: PASSWORD_RESET_SUCCESS
        });
    } catch (err) {
        dispatch({
            type: PASSWORD_RESET_FAIL
        });
        dispatch(apiRequestSuccess("ResetSuccessfull Successfull"));
        if (err.response) {
            const errorMessage = err.response.data;
            dispatch(setError(errorMessage)); // Dispatch setError action
        } else {
            dispatch(setError('Reset failed. Please try again later.'));
        }
    }
};


export const update_user = (mobile) => async(dispatch) =>{
    const config = {
        headers:{
            'Content-Type':'application/json'
        }
    };

    const body = JSON.stringify({mobile});

    try{
        await axios.post(`${baseURL}/auth/users/reset_password/`,body,config);
        dispatch({
            type: UPDATE_USER
        });
    } catch (err) {
        dispatch({
            type: UPDATE_USER_FAIL
        });
        dispatch(apiRequestSuccess("ResetSuccessfull Successfull"));
        if (err.response) {
            const errorMessage = err.response.data;
            dispatch(setError(errorMessage)); // Dispatch setError action
        } else {
            dispatch(setError('Reset failed. Please try again later.'));
        }
    }
};


export const reset_password_confirm = (uid, token, new_password, re_new_password) => async (dispatch) =>{
    console.log(uid,token,new_password,re_new_password)
    const config = {
        headers:{
            'Content-Type':'application/json'
        }
    };

    const body = JSON.stringify({uid, token, new_password, re_new_password});

    try{
        await axios.post(`${baseURL}/auth/users/reset_password_confirm/`,body,config);
        dispatch({
            type: PASSWORD_RESET_CONFIRM_SUCCESS
        });
    } catch (err) {
        dispatch({
            type: PASSWORD_RESET_CONFIRM_FAIL
        });
    }
};

export const logout = () => async (dispatch) => {
    dispatch({
        type:LOGOUT
    });
};

export const googleAuthenticate = (state,code) => async (dispatch) =>{
    if (state && code && !localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content_Type':'application/x-www-form-urlencoded'
            }
        };

        const details = {
            'state':state,
            'code':code
        };

        const formBody = Object.keys(details).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(details[key])).join('&');

        try{
            const res = await axios.post(`${baseURL}/auth/o/google-oauth2/?${formBody}`,config);

            dispatch({
                type:GOOGLE_AUTH_SUCCESS,
                payload: res.data
            });
            dispatch(load_user());
        } catch (err) {
            dispatch({
                type:GOOGLE_AUTH_FAIL,
            });
        }
    }
}