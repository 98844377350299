import React, { useState } from 'react';
import { connect } from 'react-redux';
import { logout } from '../../actions/Auth';
import { useLocation } from 'react-router-dom';
import './navbar.scss';

const Navbar = ({logout,isAuthenticated,username,loading}) => {
    const[open,setOpen] = useState(false)
    const location = useLocation();
    const isHome = location.pathname === '/';
    return (
        <nav>
        <div className="left">
            <a className="logo" href='/'>
                <img src="/static/Secira.png" alt="logo" />
                <span></span>
            </a>
            {isAuthenticated ? <a href='/user_dashboard'>Dashboard</a> : <a href='/'>Home</a>}
            <a href='/'>Services</a>
            <a href='/'>Support</a>
            <a href='/'>Company</a>
            <a href='/'>Legal</a>
        </div>
        <div className={`right ${isHome ? 'home' : ''}`}>
            {isAuthenticated ?
                <>
                    
                    <div className='user'> 
                    {loading ? (
                            // Show this if loading is true
                            <span>Loading...</span>
                        ) : (<>
                        {/* <a href="/profile"> */}
                        <a href="/user_dashboard">
                        <img src={username?.profile_image_url || 'https://th.bing.com/th?q=Profile+Emoji&w=120&h=120&c=1&rs=1&qlt=90&cb=1&dpr=1.3&pid=InlineBlock&mkt=en-IN&cc=IN&setlang=en&adlt=moderate&t=1&mw=247'} alt='' />
                        {username ? <span className='profile'>{username.username}</span> :
                        <span>user</span>}
                            <div className='notification'>
                                <a href='/notifications'></a>
                            </div>
                        </a>
                        <a  className="register" href="/" onClick={logout}>Logout</a>
                        </>
                        )}
                    </div>
                </>
                :
                <>
                    <a href="/signup/">Sign up</a>
                    <a  className="register" href="/login/">Login</a>
                </>
            }

            <div className='menuIcon'>
                <img src='/static/ham.png'  alt='menu' onClick={() => setOpen((prev) => !prev)} />
            </div>
            <div className={open ? "menu active" : "menu"}>
                {isAuthenticated ? <a href='/user_dashboard'>Dashboard</a> : <a href='/'>Home</a>}
                <a href='/'>Services</a>
                <a href='/'>Support</a>
                <a href='/'>Company</a>
                <a href='/'>Legal</a>
                {isAuthenticated ?
                    <>
                    
                        
                        <div className='user'> 
                            {/* <img src='https://i.pinimg.com/736x/66/b8/58/66b858099df3127e83cb1f1168f7a2c6.jpg' alt='' /> */}
                           
                            <a href="/">
                                <img src='https://th.bing.com/th?q=Profile+Emoji&w=120&h=120&c=1&rs=1&qlt=90&cb=1&dpr=1.3&pid=InlineBlock&mkt=en-IN&cc=IN&setlang=en&adlt=moderate&t=1&mw=247' alt='' />
                                {username ? <span className='profile'>{username.username}</span> :
                                <span>user</span>}
                                    <div className='notification'>
                                        <a href='/notifications'></a>
                                    </div>
                            </a>
                            <a  className="register" href="/" onClick={logout}>Logout</a>
                        
                        </div>
                    </>
                      :
                    <>
                        <a href="/signup/">Sign up</a>
                        <a  className="register" href="/login/">Login</a>
                    </>
                }
            </div>
        </div>
        </nav>
    );
};

const mapStateToProps = state =>({
    isAuthenticated: state.auth.isAuthenticated,
    username: state.auth.user,
    loading: state.auth.loading
})

export default connect(mapStateToProps,{logout})(Navbar);