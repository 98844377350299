import React, { useEffect } from 'react'
import  Navbar  from '../components/navbar/Navbar';
import { connect } from 'react-redux';
import { checkAuthenticated, load_user, googleAuthenticate } from '../actions/Auth';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import "./layout.scss"
import  ErrorAlert  from '../pages/error/errorAlert';
import Notifications from '../pages/notifications/Notifications';


const Layout = (props) => {
    let location = useLocation();
    useEffect(() => {
        const values = queryString.parse(location.search);
        const state = values.state ? values.state : null;
        const code = values.code ? values.code : null;

        console.log('state',state);
        console.log('code', code);

        if (state && code) {
            props.googleAuthenticate(state,code)
        } else {
            props.checkAuthenticated();
            props.load_user();
        }

    }, [location]);

    return (
        <div className='layout'>
            <Navbar />
            <div className='content'>
                <Notifications/>
                <ErrorAlert />
                {props.children}
            </div>
            
        </div>
    );
    
};


export default connect(null,{ checkAuthenticated, load_user, googleAuthenticate })(Layout)
