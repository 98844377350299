// ErrorAlert.js

import React,{ useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearError } from '../../actions/Error';
import './errorAlert.scss'

const ErrorAlert = () => {
    const dispatch = useDispatch();
    const errorMessage = useSelector(state => state.error.errorMessage);
    console.log(errorMessage)
    const handleClearError = () => {
        dispatch(clearError());
    };

    const errorCounts = {};
    if (Array.isArray(errorMessage)) {
        errorMessage.forEach(error => {
            errorCounts[error] = (errorCounts[error] || 0) + 1;
        });
    }

    return (
        <div className='errorContainer'>
        {errorMessage && Object.keys(errorMessage).map((key, index) => (
            <div className="errorBox" key={index}>
                <h4>{key}</h4>
                <ul>
                {Array.isArray(errorMessage[key]) ? (
                errorMessage[key].map((error, idx) => (
                    <li key={idx}>{error}</li>
                ))
                ) : (
                    <li>{errorMessage[key]}</li> // Handle non-array case here
                )}
        
                </ul>
                <button className="clearButton" onClick={handleClearError}>X</button>
            </div>
        ))}
    </div>
    );
};


export default ErrorAlert;



