import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link,Navigate } from 'react-router-dom';
import { login } from '../../actions/Auth';
import axios from 'axios';
import { baseURL } from '../../config';
import "../home.scss"

const Login = ({login,isAuthenticated}) => {
    const [formdata,setFormdata] = useState({
        email:"",
        password:""
    });

    const { email, password } = formdata;

    const onChange = e => setFormdata({...formdata,[e.target.name]:e.target.value});
    const onSubmit = e => {
        e.preventDefault();
        login(email,password);
    };

    const continueWithGoogle = async() =>{
        try{
            const res = await axios.get(`${baseURL}/auth/o/google-oauth2/?redirect_uri=http://localhost:8000`);

            window.location.replace(res.data.autherization_url);
        } catch (err) {

        }
    };

    //isauthenticated?
    //if not redirect to login or if yes to home page
    if (isAuthenticated){
        return <Navigate to='/'/>
    }

    return (
        <div className='homePage'>
            <div className='textContainer'>
                <div className='wrapper'>
                    <div className='title'>
                        <h1>Property Document Support for 12+ States in India</h1>
                    </div>
                    <div className='form'>
                        <h3>Sign In</h3>
                        <form onSubmit={e=>onSubmit(e)}>
                            <div className='form-group '>
                                <input className='form-control'
                                        type='email'
                                        placeholder='Email'
                                        name='email'
                                        value={email}
                                        onChange={e => onChange(e)}
                                        required
                                />
                            </div>
                            <div className='form-group'>
                                <input className='form-control'
                                        type='password'
                                        placeholder='Password'
                                        name='password'
                                        value={password}
                                        onChange={e => onChange(e)}
                                        minLength='6'
                                        required
                                />
                            </div>
                            <button type='submit'>Login</button>
                        </form>
                        {/* <button className='btn btn-danger mt-3' onClick={continueWithGoogle}>Continue With Google</button> */}
                        <p>Don't have a account ? <Link to="/signup/">Create Account</Link></p>
                        <p>Forgot your Password? <Link to="/reset-password/"> Reset Password</Link></p>
                    </div>
                </div>
            </div>
            <div className='imgContainer'>
                <img src='/static/home3.png' alt=''/>
            </div>
        </div>
    );
};

const mapStateToProps = state =>({
    isAuthenticated:state.auth.isAuthenticated
});

export default connect(mapStateToProps,{login})(Login);
