import React from 'react';
import './socialMediaHandlers.scss'; // Import the SCSS file for styling

const socialMediaLinks = [
  { name: 'Facebook', url: 'https://facebook.com', icon: 'fb1.jpg' },
  { name: 'Twitter', url: 'https://twitter.com', icon: 'tw1.jpg' },
  { name: 'Instagram', url: 'https://instagram.com', icon: 'Yt1.jpg' },
  { name: 'LinkedIn', url: 'https://linkedin.com', icon: 'link1.jpg' },
  // Add more social media links here
];

const SocialMediaHandlers = () => {
  return (
    <div className="social-media-handlers">
        <div className="text-container">
        <p className="heading">Secira</p>
        <p className="caption">Online Safe Vault for your Real Estate Assets</p>
      </div>
      <div className="icons-container">
        {socialMediaLinks.map((social, index) => (
          <a key={index} href={social.url} target="_blank" rel="noopener noreferrer" className="social-media-link">
            <img src={`https://secira-documents.s3.eu-north-1.amazonaws.com/secira_frontend_images/${social.icon}`} alt={social.name} className="social-media-icon" />
          </a>
        ))}
      </div>
    </div>
  );
};

export default SocialMediaHandlers;
