import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link,Navigate } from 'react-router-dom';
import { reset_password_confirm } from '../../actions/Auth';
import { useParams } from 'react-router-dom';
import "../home.scss"


const ResetPasswordConfirm = ({params,reset_password_confirm}) => {
    const [requestSent,setRequestSent] = useState(false);
    const [formdata,setFormdata] = useState({
        new_password:"",
        re_new_password:""
    });
    const { uid, token } = useParams();

    const { new_password,re_new_password } = formdata;

    const onChange = e => setFormdata({...formdata,[e.target.name]:e.target.value});
    const onSubmit = e => {
        e.preventDefault();
        //const { uid, token } = params;
        console.log(uid,token,new_password,re_new_password,'onSubmit')
        reset_password_confirm(uid,token,new_password,re_new_password);
        setRequestSent(true);
    };

    //isauthenticated?
    //if not redirect to login or if yes to home page
    if (requestSent){
        return <Navigate to='/'/>
    }

    return (
        <div className='homePage'>
            <div className='textContainer'>
                <div className='wrapper'>
                    <div className='title'>
                        {/* <h1>Property Document Support for 12+ States in India</h1> */}
                    </div>
                    <div className='form'>
                        <h3>Reset Password Confirmation</h3>
                        <form onSubmit={e=>onSubmit(e)}>
                            <div className='form-group mt-2'>
                                <input className='form-control'
                                        type='password'
                                        placeholder='New Password'
                                        name='new_password'
                                        value={new_password}
                                        onChange={e => onChange(e)}
                                        minLength='6'
                                        required
                                />
                            </div>
                            <div className='form-group mt-2'>
                                <input className='form-control'
                                        type='password'
                                        placeholder='Confirm Password'
                                        name='re_new_password'
                                        value={re_new_password}
                                        onChange={e => onChange(e)}
                                        minLength='6'
                                        required
                                />
                            </div>
                            <button type='submit'>Reset Password</button>
                        </form>
                    </div>
                </div>
            </div>
            <div className='imgContainer'>
                <img src='/static/home3.png' alt=''/>
            </div>
        </div> 
        
        
    );
};



export default connect(null,{reset_password_confirm})(ResetPasswordConfirm);
