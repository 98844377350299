import React, { useRef }from 'react';
import { connect } from 'react-redux';
import { clearError } from '../actions/Error';
import { useDispatch } from 'react-redux';
import "./home.scss"
import { SearchBar } from './searchbar/SearchBar';
import { Link } from 'react-router-dom';
import BubbleScroll from './bubblescroll/BubbleScroll';
import ScrollIndicator from './scroll_indi/ScrollIndicator';
import SocialMediaHandlers from './socialmediahandlers/SocialMediaHandlers';


 const Home = ({isAuthenticated,isError}) => {
    const dispatch = useDispatch();

    const wrapperRef = useRef(null);

    const handleScroll = (event) => {
        event.preventDefault();

        const { scrollTop, clientHeight, scrollHeight } = wrapperRef.current;
        const scrollDirection = event.deltaY > 0 ? 1 : -1;
        const maxScrollTop = scrollHeight - clientHeight;
        const nextScrollPosition = Math.max(0, Math.min(maxScrollTop, scrollTop + (scrollDirection * clientHeight)));

        wrapperRef.current.scrollTo({
        top: nextScrollPosition,
        behavior: 'smooth'
        });
    };


    const handleClearError = () => {
        dispatch(clearError());
    };


    return(
        isAuthenticated ? 
            (<div className='homePage'>
                <div className='textContainer'>
                    <div className='wrapper'>
                        <h1 className='title'>
                            Property Document Support for 12+ States in India
                        </h1>
                        <div className='scroll-container'
                                ref={wrapperRef}
                                onWheel={handleScroll}>
                                <div className='section'>
                                    {/* <h1 className='title'>
                                            Property Document Support for 12+ States in India
                                    </h1> */}
                                    <BubbleScroll/>
                                </div>
                                <br/>
                                <div className='section'>
                                    <div className='join-button'>
                                        <img className="whyimage" src='https://secira-documents.s3.eu-north-1.amazonaws.com/secira_frontend_images/secira_platform.jpg'/>
                                        <div className='join-container'>
                                            <h1 className='img-title'>
                                                Secira Digital Platform for Properties
                                            </h1>
                                            <h4 className='img-title4'>
                                                Manage all your activites in one Platform
                                            </h4>
                                            <a className="join" href="/signup/">Sign up</a>
                                        </div>
                                    </div>
                                    <SearchBar/>
                                </div>
                                <div className='section'>
                                    <div className='whymain'>
                                        <span className='why'>Why Secira</span>
                                        <h1 className='whytitle'>
                                            Keep your property safe
                                        </h1>
                                        <h2 className='whytitle2' style={{ fontSize: '18px' }}>
                                            Property documents can be misused or lost in many ways. 
                                            It is very important to keep them safe.  
                                            First we help you create a digital copy of all your property records, 
                                            legally verified to provide for a clear title and finally help you
                                            identify the value of your real estate assets for loans or for sale.
                                        </h2>
                                    </div>
                                    <div className='boxes'>
                                        <div className='box'>
                                            <div className="bubble-element Group cmaNaCaC2 bubble-r-container">
                                                <button className="bubble-element inner-element materialicons-Materialicon material-icons">
                                                    call_made
                                                </button>
                                            </div>
                                            <h1 className='boxtitle'>
                                                Digitize
                                            </h1>
                                            <h2 className='boxtitle2'>
                                                Storing the paper medium is outdated, inefficient, and insecure.
                                                By document scanning you convert your paper documents into 
                                                digital files that you can search, edit, and store securely. 
                                                Once electronically stored, it is easy to verify the legality of 
                                                these documents, share with other stake holders like buyers, 
                                                insurance and banks.  
                                            </h2>
                                        </div>
                                        <div className='box'>
                                            <div className="bubble-element Group cmaNaCaC2 bubble-r-container">
                                                <button className="bubble-element inner-element2 materialicons-Materialicon material-icons">
                                                    call_made
                                                </button>
                                            </div>
                                            <h1 className='boxtitle'>
                                                Legal verificaiton
                                            </h1>
                                            <h2 className='boxtitle2'>
                                                Buying or selling a property is expensive and time consuming activity. 
                                                Property documents have to be verified for any pending litigations or
                                                financial issues, clear ownership title and completeness. 
                                                Finding the right legal expertise is very difficult and we 
                                                can help you with that.   
                                            </h2>
                                        </div>
                                        <div className='box'>
                                            <div className="bubble-element Group cmaNaCaC2 bubble-r-container">
                                                <button className="bubble-element inner-element3 materialicons-Materialicon material-icons">
                                                    call_made
                                                </button>
                                            </div>
                                            <h1 className='boxtitle'>
                                                Valuations
                                            </h1>
                                            <h2 className='boxtitle2'>
                                                Knowing the real price of the property is important for both 
                                                buyer and sellers. Getting the valuation from a certified team helps you to
                                                plan for the sale or loan clearly. This helps in reduced negotiation and 
                                                quicker sales process. With a predefined and certified valuation report 
                                                seller can easily discuss with banks and buyers.   
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                                <div className='section'>
                                    <span className='why'>Property Services</span>
                                    <h2 className='title2'>
                                        Secira Digital Platform for Properties
                                        Manage all your activites in one Platform
                                    </h2>
                                    <div className='boxes'>
                                        <div className='box'>
                                            <h1 className='boxtitle' style={{ fontSize: '24px' }}>
                                                Property Services
                                            </h1>
                                            <h2 className='boxtitle2' style={{ fontSize: '18px' }}>
                                                Rental Manager helps you to share, discuss and finalize with potential tenants.
                                                With one click you can share your property details, pictures, agreements,
                                                references and electronically sign it. It helps you to monitor the lease periods, 
                                                renewals and rent payments easily. 
                                            </h2>
                                        </div>
                                        <div className='box'>
                                            <img className="boximage" src='https://secira-documents.s3.eu-north-1.amazonaws.com/secira_frontend_images/rental.jpg'/>
                                    </div>
                                </div>
                                </div>
                                <div className='section'>
                                    <div className='boxes'>
                                        <div className='box'>
                                            <SocialMediaHandlers/>
                                        </div>
                                        <div className='box'>
                                            {/* <div className="bubble-element Group cmaNaCaC2 bubble-r-container">
                                                <button className="bubble-element inner-element materialicons-Materialicon material-icons">
                                                    call_made
                                                </button>
                                            </div> */}
                                            <h1 className='boxtitle3'>
                                                Services
                                            </h1>
                                            <h2 className='boxtitle4'>
                                                Document Vault  
                                            </h2>
                                            <h2 className='boxtitle4'>
                                                Legal Services  
                                            </h2>
                                            <h2 className='boxtitle4'>
                                                Valuations 
                                            </h2>
                                            <h2 className='boxtitle4'>
                                                Property Service
                                            </h2>
                                            <h2 className='boxtitle4'>
                                                Market Insights 
                                            </h2>
                                        </div>
                                        <div className='box'>
                                            {/* <div className="bubble-element Group cmaNaCaC2 bubble-r-container">
                                                <button className="bubble-element inner-element materialicons-Materialicon material-icons">
                                                    call_made
                                                </button>
                                            </div> */}
                                            <h1 className='boxtitle3'>
                                                Support
                                            </h1>
                                            <h2 className='boxtitle4'>
                                                Documentation 
                                            </h2>
                                            <h2 className='boxtitle4'>
                                                Help Center  
                                            </h2>
                                            <h2 className='boxtitle4'>
                                                APIs
                                            </h2>
                                        </div>
                                        <div className='box'>
                                            {/* <div className="bubble-element Group cmaNaCaC2 bubble-r-container">
                                                <button className="bubble-element inner-element materialicons-Materialicon material-icons">
                                                    call_made
                                                </button>
                                            </div> */}
                                            <h1 className='boxtitle3'>
                                                Company
                                            </h1>
                                            <h2 className='boxtitle4'>
                                                About  
                                            </h2>
                                            <h2 className='boxtitle4'>
                                                Partners 
                                            </h2>
                                            <h2 className='boxtitle4'>
                                                Careers
                                            </h2>
                                            <h2 className='boxtitle4'>
                                                BUilders
                                            </h2>
                                            <h2 className='boxtitle4'>
                                                Investors 
                                            </h2>
                                        </div>
                                        <div className='box'>
                                            {/* <div className="bubble-element Group cmaNaCaC2 bubble-r-container">
                                                <button className="bubble-element inner-element materialicons-Materialicon material-icons">
                                                    call_made
                                                </button>
                                            </div> */}
                                            <h1 className='boxtitle3'>
                                                Legal
                                            </h1>
                                            <h2 className='boxtitle4'>
                                                Privacy Policy  
                                            </h2>
                                            <h2 className='boxtitle4'>
                                                Terms  
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                {/* <div className='imgContainer'>
                    <img src='/static/home3.png' alt=''/>
                </div> */}
            </div> ):
        
        (<div className='homePage'>
            {/* <SocialMediaHandlers/> */}
            <div className='textContainer'>
                {/* <h1 className='title'>
                        Property Document Support for 12+ States in India
                </h1> */}
                <ScrollIndicator/>
                <div className='wrapper'>
                    {/* <h2 className='title2'>
                        Secira Digital Platform for Properties
                        Manage all your activites in one Platform
                    </h2> */}
                    {/* <p>
                        content
                    </p> */}
                    {/* <SearchBar/> */}
                    <div className='scroll-container'
                        ref={wrapperRef}
                        onWheel={handleScroll}>
                        <div className='section'>
                            <h1 className='title'>
                                    Property Document Support for 12+ States in India
                            </h1>
                            <BubbleScroll/>
                        </div>
                        <br/>
                        <div className='section'>
                            <div className='join-button'>
                                <img className="whyimage" src='https://secira-documents.s3.eu-north-1.amazonaws.com/secira_frontend_images/secira_platform.jpg'/>
                                <div className='join-container'>
                                    <h1 className='img-title'>
                                        Secira Digital Platform for Properties
                                    </h1>
                                    <h4 className='img-title4'>
                                        Manage all your activites in one Platform
                                    </h4>
                                    <a className="join" href="/signup/">Sign up</a>
                                </div>
                            </div>
                            <SearchBar/>
                        </div>
                        <div className='section'>
                            <div className='whymain'>
                                <span className='why'>Why Secira</span>
                                <h1 className='whytitle'>
                                    Keep your property safe
                                </h1>
                                <h2 className='whytitle2' style={{ fontSize: '18px' }}>
                                    Property documents can be misused or lost in many ways. 
                                    It is very important to keep them safe.  
                                    First we help you create a digital copy of all your property records, 
                                    legally verified to provide for a clear title and finally help you
                                    identify the value of your real estate assets for loans or for sale.
                                </h2>
                            </div>
                            <div className='boxes'>
                                <div className='box'>
                                    <div className="bubble-element Group cmaNaCaC2 bubble-r-container">
                                        <button className="bubble-element inner-element materialicons-Materialicon material-icons">
                                            call_made
                                        </button>
                                    </div>
                                    <h1 className='boxtitle'>
                                        Digitize
                                    </h1>
                                    <h2 className='boxtitle2'>
                                        Storing the paper medium is outdated, inefficient, and insecure.
                                        By document scanning you convert your paper documents into 
                                        digital files that you can search, edit, and store securely. 
                                        Once electronically stored, it is easy to verify the legality of 
                                        these documents, share with other stake holders like buyers, 
                                        insurance and banks.  
                                    </h2>
                                </div>
                                <div className='box'>
                                    <div className="bubble-element Group cmaNaCaC2 bubble-r-container">
                                        <button className="bubble-element inner-element2 materialicons-Materialicon material-icons">
                                            call_made
                                        </button>
                                    </div>
                                    <h1 className='boxtitle'>
                                        Legal verificaiton
                                    </h1>
                                    <h2 className='boxtitle2'>
                                        Buying or selling a property is expensive and time consuming activity. 
                                        Property documents have to be verified for any pending litigations or
                                        financial issues, clear ownership title and completeness. 
                                        Finding the right legal expertise is very difficult and we 
                                        can help you with that.   
                                    </h2>
                                </div>
                                <div className='box'>
                                    <div className="bubble-element Group cmaNaCaC2 bubble-r-container">
                                        <button className="bubble-element inner-element3 materialicons-Materialicon material-icons">
                                            call_made
                                        </button>
                                    </div>
                                    <h1 className='boxtitle'>
                                        Valuations
                                    </h1>
                                    <h2 className='boxtitle2'>
                                        Knowing the real price of the property is important for both 
                                        buyer and sellers. Getting the valuation from a certified team helps you to
                                        plan for the sale or loan clearly. This helps in reduced negotiation and 
                                        quicker sales process. With a predefined and certified valuation report 
                                        seller can easily discuss with banks and buyers.   
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className='section'>
                            <span className='why'>Property Services</span>
                            <h2 className='title2'>
                                Secira Digital Platform for Properties
                                Manage all your activites in one Platform
                            </h2>
                            <div className='boxes'>
                                <div className='box'>
                                    <h1 className='boxtitle' style={{ fontSize: '24px' }}>
                                        Property Services
                                    </h1>
                                    <h2 className='boxtitle2' style={{ fontSize: '18px' }}>
                                        Rental Manager helps you to share, discuss and finalize with potential tenants.
                                        With one click you can share your property details, pictures, agreements,
                                        references and electronically sign it. It helps you to monitor the lease periods, 
                                        renewals and rent payments easily. 
                                    </h2>
                                </div>
                                <div className='box'>
                                    <img className="boximage" src='https://secira-documents.s3.eu-north-1.amazonaws.com/secira_frontend_images/rental.jpg'/>
                            </div>
                        </div>
                        </div>
                        <div className='section'>
                            <div className='boxes'>
                                <div className='box'>
                                    <SocialMediaHandlers/>
                                </div>
                                <div className='box'>
                                    {/* <div className="bubble-element Group cmaNaCaC2 bubble-r-container">
                                        <button className="bubble-element inner-element materialicons-Materialicon material-icons">
                                            call_made
                                        </button>
                                    </div> */}
                                    <h1 className='boxtitle3'>
                                        Services
                                    </h1>
                                    <h2 className='boxtitle4'>
                                        Document Vault  
                                    </h2>
                                    <h2 className='boxtitle4'>
                                        Legal Services  
                                    </h2>
                                    <h2 className='boxtitle4'>
                                        Valuations 
                                    </h2>
                                    <h2 className='boxtitle4'>
                                        Property Service
                                    </h2>
                                    <h2 className='boxtitle4'>
                                        Market Insights 
                                    </h2>
                                </div>
                                <div className='box'>
                                    {/* <div className="bubble-element Group cmaNaCaC2 bubble-r-container">
                                        <button className="bubble-element inner-element materialicons-Materialicon material-icons">
                                            call_made
                                        </button>
                                    </div> */}
                                    <h1 className='boxtitle3'>
                                        Support
                                    </h1>
                                    <h2 className='boxtitle4'>
                                        Documentation 
                                    </h2>
                                    <h2 className='boxtitle4'>
                                        Help Center  
                                    </h2>
                                    <h2 className='boxtitle4'>
                                        APIs
                                    </h2>
                                </div>
                                <div className='box'>
                                    {/* <div className="bubble-element Group cmaNaCaC2 bubble-r-container">
                                        <button className="bubble-element inner-element materialicons-Materialicon material-icons">
                                            call_made
                                        </button>
                                    </div> */}
                                    <h1 className='boxtitle3'>
                                        Company
                                    </h1>
                                    <h2 className='boxtitle4'>
                                        About  
                                    </h2>
                                    <h2 className='boxtitle4'>
                                        Partners 
                                    </h2>
                                    <h2 className='boxtitle4'>
                                        Careers
                                    </h2>
                                    <h2 className='boxtitle4'>
                                        BUilders
                                    </h2>
                                    <h2 className='boxtitle4'>
                                        Investors 
                                    </h2>
                                </div>
                                <div className='box'>
                                    {/* <div className="bubble-element Group cmaNaCaC2 bubble-r-container">
                                        <button className="bubble-element inner-element materialicons-Materialicon material-icons">
                                            call_made
                                        </button>
                                    </div> */}
                                    <h1 className='boxtitle3'>
                                        Legal
                                    </h1>
                                    <h2 className='boxtitle4'>
                                        Privacy Policy  
                                    </h2>
                                    <h2 className='boxtitle4'>
                                        Terms  
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='imgContainer'>
                <img src='./home3.png' alt='' />
            </div> */}
        </div>
    ));
    
 };

 const mapStateToProps = state =>({
    isAuthenticated:state.auth.isAuthenticated,
    isError:state.error.errorMessage
});

export default connect(mapStateToProps,{clearError})(Home);
