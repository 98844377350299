import { SET_ERROR, CLEAR_ERROR } from '../actions/Types';

const initialState = {
    errorMessage: null,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case SET_ERROR:
            return {
                ...state,
                errorMessage: action.payload,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                errorMessage: null,
            };
        default:
            return state;
    }
};


