import React from 'react';
import './di.scss';
const DocumentInput = ({ document, onChange, propertyType, documentTypes, onDelete }) => {
//   const handleChange = (e) => {
//     const { name, value, files } = e.target;
//     if (files && files[0]) {
//         console.log('File attached:', files[0].name);
//     } else {
//     console.log('No file attached.');
//     }
//     onChange({
//       ...document,
//       [name]: files ? files[0] : value,
//     });
//   };

  const handleChange = (e) => {
    const { name, files, value } = e.target;
    
    // Ensure that the files are added properly
    if (files && files.length > 0) {
      const selectedFile = files[0];
      console.log('File attached:', selectedFile.name);  // Log file name when selected
      onChange({
        ...document,
        [name]: selectedFile  // Set the file as the state value
      });
    } else {
      onChange({
        ...document,
        [name]: value
      });
    }
    }

  return (
    <div className="document-card">
        <div className="form-group">
            <label>Document Image:</label>
            <input type="file" name="documentImage" onChange={handleChange} />
        </div>

        <div className="form-group">
            <label>Document PDF:</label>
            <input type="file" name="documentPdf" onChange={handleChange} />
        </div>

        <div className="form-group">
            <label>Document Type:</label>
            <select name="documentType" value={document.documentType || ''} onChange={handleChange}>
            <option value="">Select Document Type</option>
            {documentTypes.map((docType) => (
                <option key={docType.id} value={docType.id}>
                {docType.document_type}
                </option>
            ))}
            </select>
        </div>

        <div className="form-group">
            <label>Property Type:</label>
            <input type="text" name="propertyType" value={propertyType || ''} onChange={handleChange} />
        </div>

        {/* Delete button */}
        <div className="form-group">
            <button type="button" onClick={onDelete} className="delete-document-btn">
            Delete Document
            </button>
        </div>
    </div>

  );
};

export default DocumentInput;
