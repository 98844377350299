import './App.css';
import { ExpressionForm } from './features/expressionform';
import { BrowserRouter as Router } from 'react-router-dom'
import  Layout  from './hocs/Layout';
import AppRoutes from './approutes/AppRoutes';
import { Provider } from 'react-redux';
import store from './store/Store';

const App = () =>(
  <Provider store={store}>
    <Router>
      <Layout>
        <AppRoutes/>
      </Layout>
    </Router>
  </Provider>
);

 

export default App;
