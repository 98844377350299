import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOAD_USER_SUCCESS,
    LOAD_USER_FAIL,
    SIGNUP_SUCCESS,
    SIGNUP_FAIL,
    ACTIVATION_SUCCESS,
    ACTIVATION_FAIL,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAIL,
    PASSWORD_RESET_CONFIRM_SUCCESS,
    PASSWORD_RESET_CONFIRM_FAIL,
    LOGOUT,
    GOOGLE_AUTH_SUCCESS,
    GOOGLE_AUTH_FAIL,
    UPDATE_USER,
    UPDATE_USER_FAIL,

} from '../actions/Types';

const initialState ={
    access: localStorage.getItem('access'),
    refresh: localStorage.getItem('refresh'),
    isAuthenticated: null,
    user:null ,
    loading: true,
}

export default function(state = initialState,action){
    const { type, payload } = action;

    switch(type) {
        case AUTHENTICATED_SUCCESS:
            return{
                ...state,
                isAuthenticated:true
            }
        case AUTHENTICATED_FAIL:
            return{
                ...state,
                isAuthenticated:false
            }
        case SIGNUP_SUCCESS:
            return{
                ...state,
                isAuthenticated:false
            }
        case LOGIN_SUCCESS:
            console.log('reducer  access',payload.access,'refresh',payload.refresh)
            localStorage.setItem('access',payload.access);
            localStorage.setItem('refresh',payload.refresh);
            console.log(localStorage)
            return{
                ...state,
                isAuthenticated: true,
                access: payload.access,
                refresh:payload.refresh,
                loading: false,

            }
        case GOOGLE_AUTH_SUCCESS:
            localStorage.setItem('access',payload.access);
            return {
                ...state,
                isAuthenticated:true,
                access: payload.access,
                refresh: payload.refresh
            }
        case GOOGLE_AUTH_FAIL:
        case LOGIN_FAIL:
        case LOGOUT:
        case SIGNUP_FAIL:
            console.log("login fail")
            localStorage.removeItem('access');
            localStorage.removeItem('refresh')
            return{
                ...state,
                isAuthenticated:null,
                access:null,
                refresh:null,
                user:null,
                loading: false,

            }
        case LOAD_USER_SUCCESS:
            console.log("user success")
            return{
                ...state,
                user:payload,
                loading: false,
            }
        case LOAD_USER_FAIL:
            console.log("user fail")
            return{
                ...state,
                user:null,
                loading: false,

            }
        case PASSWORD_RESET_SUCCESS:
        case PASSWORD_RESET_FAIL:
        case PASSWORD_RESET_CONFIRM_SUCCESS:
        case PASSWORD_RESET_CONFIRM_FAIL:
        case ACTIVATION_SUCCESS:
        case ACTIVATION_FAIL:
            return{
                ...state
            }
        // authReducer.js
        case UPDATE_USER:
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload, // Update the user with new data
                },
            };
        case UPDATE_USER_FAIL:
            return {
                ...state,
                loading: false,
            };
        default:
            return state
    }
}