import React from 'react';
import { Route, Routes } from 'react-router-dom';
import  Home  from '../pages/Home';
import  Login from '../pages/user/Login';
import  Signup  from '../pages/user/Signup';
import  Activate  from '../pages/user/Activate';
import  ResetPassword  from '../pages/user/ResetPassword';
import  ResetPasswordConfirm  from '../pages/user/RestPasswordConfirm';
import { ListPage } from '../pages/list/ListPage';
import UserDashboard from '../pages/dashboard/UserDashboard';
import { SInglePage } from '../pages/singlepage/SInglePage';
import { SInglePage2 } from '../pages/singlepage/SinglePage2';
import  AddListItem  from '../pages/addlistitem/AddListItem';
import EditListItem from '../pages/addlistitem/EditListItem';


const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/login/" Component={Login } />
    <Route path="/signup/" element={<Signup />} />
    <Route path="/activate/:uid/:token" element={<Activate />} />
    <Route path="/reset-password/" element={<ResetPassword />} />
    <Route path="/password/reset/confirm/:uid/:token" element={<ResetPasswordConfirm />} />
    <Route path="/user_dashboard" element={<UserDashboard/>} />
    <Route path="/list" element={<ListPage/>} />
    <Route path="/:id" element={<SInglePage/>} />
    <Route path="/item/:id" element={<SInglePage2/>} />
    <Route path="/addlistitem" element={<AddListItem/>} />
    <Route path="/edit-listitem" element={<EditListItem/>} />
  </Routes>
);

export default AppRoutes;