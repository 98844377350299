import React from 'react'
import { Slider } from '../../components/slider/Slider';
import { singlePostData } from '../../lib/dummydata';
import { Map } from '../../components/map/Map';
import './singlepage.scss'
import { useLocation } from 'react-router-dom';

export const SInglePage = () => {
  const location = useLocation();
  const {propertyData, userinfo} = location.state || {};
  console.log(propertyData)
  console.log(propertyData.property.images)
  console.log(propertyData.property.city)
  console.log(userinfo)

  if (!propertyData) return <div>No data available</div>;

  return (
    <div className='singlepage'>
      <div className='details'>
        <div className="wrapper">
          <Slider images={propertyData.property.images}/>
          <div className="info">
            <div className="top">
              <div className="post">
                <h1>{singlePostData.title}</h1>
                <div className="address">
                  <img src='/static/pin.png' alt=''/>
                  <span>{propertyData.property.address1}</span>
                </div>
                <div className="price">$ {propertyData.property.market_value}</div>
              </div>
              <div className="user">
                {/* <img src={userData.img} alt=''/> */}
                <div>
                  <p>User: {userinfo.username}</p>
                  <p>Email: {userinfo.email}</p>
                  {/* Render other user information here */}
              </div>
              </div>
            </div>
            <div className="bottom">
              {propertyData.property.property_type}
            </div>
          </div>
        </div>
      </div>
      <div className='features'>
        <div className="wrapper">
          <p className='title'>General</p>
          <div className="listvertical">
            <div className="feature">
              <img src="/static/utility.png" alt="" />
              <div className="featuretext">
                <span>Utilities</span>
                <p>Renter is Responsible</p>
              </div>
            </div>
            <div className="feature">
              <img src="/static/pet.png" alt="" />
              <div className="featuretext">
                <span>Pet Policy</span>
                <p>Pets Allowed</p>
              </div>
            </div>
            <div className="feature">
              <img src="/static/fee.png" alt="" />
              <div className="featuretext">
                <span>Maintenance Fees</span>
                <p>{propertyData.property.maintenance_cost}</p>
              </div>
            </div>
          </div>
          <p className='title'>Sizes</p>
          <div className='sizes'>
            <div className="size">
              <img src="/static/size.png" alt="" />
              <span>80 sqft</span>
            </div>
            <div className="size">
              <img src="/static/bed.png" alt="" />
              <span>2 Beds</span>
            </div>
            <div className="size">
              <img src="/static/bath.png" alt="" />
              <span>2 Baths</span>
            </div>
          </div>
          <p className='title'>Nearby Places</p>
          <div className="listhorizontal">
            <div className="feature">
              <img src="/static/school.png" alt="" />
              <div className="featureText">
                <span>School</span>
                <p>250m away</p>
              </div>
            </div>
            <div className="feature">
              <img src="/static/pet.png" alt="" />
              <div className="featureText">
                <span>Bus Stop</span>
                <p>100m away</p>
              </div>
            </div>
            <div className="feature">
              <img src="/static/fee.png" alt="" />
              <div className="featureText">
                <span>Restaurant</span>
                <p>200m away</p>
              </div>
            </div>
          </div>
          <p className='title'>Location</p>
          <div className="mapContainer">
            <Map items={[singlePostData]}/>
          </div>
          <div className="buttons">
            <button>
              <img src="/static/chat.png" alt="" />
              Send a Message
            </button>
            <button>
              <img src="/static/save.png" alt="" />
              Save the Place
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
