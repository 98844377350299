// src/ScrollIndicator.js
import React from 'react';
import '../home.scss'; // Import the CSS file for styling

const ScrollIndicator = () => {
  return (
    <div className="scroll-indicator">
      <div className="scroll-content">
        {/* <p>Scroll</p> */}
        <div className="arrow">
          <span>&darr;</span>
        </div>
      </div>
    </div>
  );
};

export default ScrollIndicator;
