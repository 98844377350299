import React from 'react';
import { MapContainer, TileLayer} from 'react-leaflet';
import './map.scss';
import "leaflet/dist/leaflet.css";
import { Pin } from '../pin/Pin';

export const Map = ({items}) => {
  return (
    <MapContainer center={[52.4767, -1.90269]} zoom={7} scrollWheelZoom={false} className='map'>
        <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {items.map(item=>(
            <Pin items={item} key={item.id}/>
        ))}
        
    </MapContainer>
  )
}
