import "./searchBar.scss";

import React, { useState } from 'react'

const types = ["buy","rent"]

export const SearchBar = () => {
    const [query, setQuery] = useState({
        type:"buy",
        location:"",
        minPrice:0,
        maxPrice:0
    })

    const switchType = (val) => {
        setQuery((prev)=>({...prev,type:val}));
    };


    return (
        <div className="searchBar">
            <div className="type">
                {types.map((type)=>(
                    <button key={type} onClick={()=>switchType(type)} className={query.type === type?"active": ""}>{type}</button>
                ))}
            </div>
            <form>
                <input type="text" name="location" placeholder="citylocation"/>
                <input type="number" name="min_price" min={0} max={100000000} placeholder="min_price"/>
                <input type="number" name="max_price" min={0} max={100000000} placeholder="max_price"/>
                <button>
                    <img src="../search.png" alt=""/>
                </button>
            </form>
        </div>
    )
}
