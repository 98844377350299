// actions.js

import { API_REQUEST_SUCCESS, CLEAR_NOTIFICATION } from './Types';

export const apiRequestSuccess = (message) => ({
  type: API_REQUEST_SUCCESS,
  payload: { message }
});

export const clearNotification = () => ({
  type: CLEAR_NOTIFICATION
});
