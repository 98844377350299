import React from 'react'
import { listData } from '../../lib/dummydata'
import "./listpage.scss"
import { Filter } from '../../components/filter/Filter'
import { Card } from '../../components/card/Card'
import { Map } from '../../components/map/Map'

export const ListPage = ({properties}) => {
    const data = properties
  return (
    <div className='listpage'>
        <div className='listcontainer'>
            <div className='wrapper'>
                <Filter/>
                {data.map((item) => (
                    <Card key={item.id} item={item}/>
                ))}
            </div>
        </div>
        <div className='mapcontainer'>
            <Map items={data}/>
        </div>
    </div>
  )
}
