
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link,Navigate } from 'react-router-dom';
import { singup, continueWithGoogle } from '../../actions/Auth';
import axios from 'axios';
import { baseURL } from '../../config';
import { useDispatch, useSelector } from 'react-redux';
import { clearError } from '../../actions/Error';
import "../home.scss";

const Signup = ({singup,isAuthenticated,isError}) => {
    const dispatch = useDispatch();
    // const errorMessage = useSelector((state) => state.error.errorMessage);
    // const notifications = useSelector((state) => state.notifications.notifications)
    // const [acc_created,setAcc_Created] = useState(false)
    const [formdata,setFormdata] = useState({
        email:"",
        username:"",
        first_name:"",
        last_name:"",
        mobile:"",
        password:"",
        re_password:""
    });
    
    const {email, username, mobile, first_name, last_name, password, re_password } = formdata;

    const onChange = e => setFormdata({...formdata,[e.target.name]:e.target.value});
    const onSubmit = e => {
        e.preventDefault();
        if (password === re_password){
            singup(email,username,mobile,first_name,last_name,password,re_password);
            // console.log(errorMessage,"error msg in the sign up")
            // {errorMessage && setAcc_Created(true)}
            
        }
    };


    const continueWithGoogle = async() =>{
        try{
            const res = await axios.get(`${baseURL}/auth/o/google-oauth2/?redirect_uri=http://localhost:8000`);
            console.log(res,"sugn up res after the oauth call")
            window.location.replace(res.data.autherization_url);
        } catch (err) {

        }
    };
    const handleClearError = () => {
        dispatch(clearError());
    };

    //isauthenticated?
    //if not redirect to login or if yes to home page
    if (isAuthenticated){
        console.log("from sign up is authenticated")
        return <Navigate to='/'/>
    }


    // if (acc_created){
    //     console.log(isError,"from sign up is acc_created")
    //     return <Navigate to='/'/>
    // }

    const isMobileValid = mobile.length === 0 || (mobile.length >= 10 && /^\d+$/.test(mobile));


    return (
                <div className='homePage'>
                    <div className='textContainer'>
                        <div className='wrapper'>
                            {/* <div className='title'>
                                <h1>Property Document Support for 12+ States in India</h1>
                            </div> */}
                            <div className='form'>
                                <h3>Create Account</h3>
                                <form onSubmit={e=>onSubmit(e)}>
                                    <div className='form-group'>
                                        <input className='form-control'
                                                type='email'
                                                placeholder='Email*'
                                                name='email'
                                                value={email}
                                                onChange={e => onChange(e)}
                                                required
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <input className='form-control'
                                                type='text'
                                                placeholder='UserName*'
                                                name='username'
                                                value={username}
                                                onChange={e => onChange(e)}
                                                required
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <input className='form-control'
                                                type='text'
                                                placeholder='FirstName*'
                                                name='first_name'
                                                value={first_name}
                                                onChange={e => onChange(e)}
                                                required
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <input className='form-control'
                                                type='text'
                                                placeholder='LastName*'
                                                name='last_name'
                                                value={last_name}
                                                onChange={e => onChange(e)}
                                                required
                                        />
                                    </div>
                                    <div className={`form-group ${isMobileValid ? '' : 'has-danger'}`}>
                                        <input
                                            className={`form-control ${isMobileValid ? '' : 'is-invalid'}`}
                                            type='text'
                                            placeholder='Mobile*'
                                            name='mobile'
                                            value={mobile}
                                            onChange={onChange}
                                            required
                                        />
                                        {!isMobileValid && (
                                            <div className='invalid-feedback'>
                                                Mobile number must be numeric and at least 10 digits long.
                                            </div>
                                        )}
                                    </div>
                                    
                                    <div className='form-group'>
                                        <input className='form-control'
                                                type='password'
                                                placeholder='Password*'
                                                name='password'
                                                value={password}
                                                onChange={e => onChange(e)}
                                                minLength='8'
                                                required
                                        />
                                        <small className="form-text text-muted">Password must be at least 8 characters long.</small>
                                    </div>
                                    <div className='form-group'>
                                        <input className='form-control'
                                                type='password'
                                                placeholder='Confirm Password*'
                                                name='re_password'
                                                value={re_password}
                                                onChange={e => onChange(e)}
                                                minLength='8'
                                                required
                                        />
                                        {password !== re_password && <small className="form-text text-danger">Passwords do not match.</small>}
                                    </div>
                                    <button className='btn btn-primary'
                                            type='submit'>Sign Up</button>
                                </form>
                                {/* <button className='btn btn-danger mt-3' onClick={continueWithGoogle}>Continue With Google</button> */}
                                <p className='mt-3'>Already have an account? <Link to="/signin/">Sing In</Link></p>
                            </div>
                        </div>
                    </div>
                    <div className='imgContainer'>
                        <img src='../home3.png' alt=''/>
                    </div>
                </div>
        // <div className='container mt-5'>
           
        //     {acc_created && <div className="success-message">Account created successfully!</div>}
        //     <h1>Sign Un</h1>
        //     <p>Create your account</p>
           
        //     {/* <button className='btn btn-danger mt-3' onClick={continueWithGoogle}>Continue With Google</button>
        //     <p className='mt-3'>Already have an account? <Link to="/signin/">Sing In</Link></p> */}
        // </div>
        
    );
};

const mapStateToProps = state =>({
    isAuthenticated:state.auth.isAuthenticated,
});

export default connect(mapStateToProps,{singup,clearError})(Signup);
