import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { clearError } from '../../actions/Error';
import { List } from '../../components/lists/List'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { baseURL } from '../../config';
import "./userDashboard.scss";
import  ProfileUpdateForm  from '../../components/profile/Profile';
// import  AddItemForm from '../../components/additemform/AddItemForm'
import { UPDATE_USER } from '../../actions/Types';
import axios from 'axios';
import AddListItem from '../addlistitem/AddListItem';


 const UserDashboard = ({isAuthenticated,isError,user,loading,updateUser,access}) => {
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [isAddItemOpen, setAddItemOpen] = useState(false);
    const navigate = useNavigate();
    const [properties, setProperties] = useState([]);
    const [_loading, setLoading] = useState(true);
    const [_error, setError] = useState(null);
    const token  = access || {};
    const userinf = user || {};
    console.log(userinf)

    // // Access the authentication token from the Redux store
    // const authToken = useSelector((state) => state.auth);

    const handleUpdate = (updatedData) => {
        // Dispatch an action to update the user profile in the store
        updateUser(updatedData);
    };

    useEffect(() => {
        // Function to fetch the property list based on userId
        const fetchProperties = async () => {
          try {
            setLoading(true);
            const response = await axios.get(`${baseURL}/api/properties/user/${user.id}/`,{
                headers: {
                    Authorization: `JWT ${token}`,
                  },
            });
            setProperties(response.data);
          } catch (err) {
            setError('Error fetching property data');
          } finally {
            setLoading(false);
          }
        };
    
        if (!loading) {
          fetchProperties();
        }
      }, [loading,user]);
    

    // // Function to handle button click
    // const handleButtonClick = () => {
    //     // If authenticated, navigate to another page
    //     navigate('/addlistitem', { state: { token: authToken } });
    // };

    // Function to handle button click
    const handleButtonClick = () => {
        // If authenticated, navigate to another page
        navigate('/addlistitem');
    };


     // Access the authentication token from the Redux store
    const authStore = useSelector((state) => state.auth);

    
        

    const handleSubmitItem = async (itemData) => {
        try {
          // Replace with your backend API URL
          const response = await axios.post('/api/items/add', itemData);
    
          // Assuming the API returns the updated list of items after adding the new item
          const updatedItems = response.data.items;
    
          // Update the user data in the Redux store with the updated items list
          updateUser({ items: updatedItems });
    
          // Close the popup after successful submission
          setAddItemOpen(false);
        } catch (error) {
          console.error('Failed to add item:', error);
          // Handle error (e.g., show an error message)
        }
      };

    // Conditional rendering based on loading state
    if (loading) {
        return <div className='loading'>Loading...</div>;
    }
    return(
        (isAuthenticated ?
        <div className='userPage'>
            <div className='details'>
                <div className='wrapper'>
                    {/* <div className='title'>
                        <h1>User information</h1>
                        <button> update Profile</button>
                    </div>
                    <div className='info'>
                        <span>
                            Avatar:
                            <img src='https://th.bing.com/th?q=Profile+Emoji&w=120&h=120&c=1&rs=1&qlt=90&cb=1&dpr=1.3&pid=InlineBlock&mkt=en-IN&cc=IN&setlang=en&adlt=moderate&t=1&mw=247' alt="" />
                        </span>
                         <span>UserName: <b>{user.username}</b></span>
                         <span> E-Mail: <b>{user.email}</b></span>
                        <button> update Profile</button>
                    </div> */}
                    {/* <div className='title'>
                        <h1>
                            My List
                        </h1>
                        <button> create new group</button>
                    </div> */}
                    {/* <div className="info">
                            <span>
                                Avatar:
                                <img src='https://th.bing.com/th?q=Profile+Emoji&w=120&h=120&c=1&rs=1&qlt=90&cb=1&dpr=1.3&pid=InlineBlock&mkt=en-IN&cc=IN&setlang=en&adlt=moderate&t=1&mw=247' alt="" />
                            </span>
                            <span>
                                Username: <b>{user.username}</b>
                            </span>
                            <span>
                                Email: <b>{user.email}</b>
                            </span>
                        </div> */}
                    <div className='title'>
                        <h1 className='h1'>My List</h1>
                        {/* <button className='b1'>Add to List</button> */}
                        {/* <button className='b1' onClick={() => setAddItemOpen(true)}>Add to List</button> */}
                        <button className='b1' onClick={handleButtonClick}>Add to List</button>
                        {/* {isAddItemOpen && (
                            <AddListItem
                                user={user} 
                                onClose={() => setAddItemOpen(false)} 
                                onUpdate={handleSubmitItem} 
                            />
                        )} */}

                    </div>
                    <List properties = {properties} user={userinf} token = {token}/>
                    {/* <div className='title'>
                        <h1>saved List</h1>
                    </div> */}
                    {/* <List/> */}
                    {/* <div className='info'>
                        <span>Avatar:
                            <img src='' alt=''/>
                         </span>
                         <span>UserName: <b>John Doe</b></span>
                         <span> E-Mail: <b>John@gmail.com</b></span>
                        <button> update Profile</button>
                    </div> */}
                    {/* <div className='title'>
                        <h1>
                            My List
                        </h1>
                        <button> create new group</button>
                    </div> */}
                    <div className='title'>
                        {/* <h1>
                            saved list
                        </h1> */}
                    </div>
                </div>
                </div>
            <div className='chatcontainer'>
                <div className='wrapper' >
                <div className='title'>
                        <h1 className='chath1'>User information</h1>
                        {/* <button className='chatb1'> update Profile</button> */}
                        <button className='chatb1' onClick={() => setIsFormOpen(true)}>Update Profile</button>

                        {isFormOpen && (
                            <ProfileUpdateForm 
                                user={user} 
                                onClose={() => setIsFormOpen(false)} 
                                onUpdate={handleUpdate} 
                            />
                        )}

                        {/* Other content for the UserDashboard */}
                    </div>
                    <div className='info'>
                        <span>
                            Avatar:
                            <img className='avatar' src={user.profile_image_url} alt="" />
                        </span>
                         <span>UserName: <b>{user.username}</b></span>
                         <span> E-Mail: <b>{user.email}</b></span>
                        {/* <button> update Profile</button> */}
                    </div>
                </div>
                {/* <img src='/static/home3.png' alt=''/> */}
            </div>
        </div> : null)
    );
        
    //     isAuthenticated ? (
    //         <div style={{
    //             backgroundColor: '#f8f9fa',
    //             padding: '2rem',
    //             marginBottom: '2rem',
    //             borderRadius: '0.3rem',
    //             boxShadow: '0 0.2rem 0.5rem rgba(0, 0, 0, 0.1)'
    //         }}>
    //             <h1 style={{ fontSize: '2.5rem', marginBottom: '1rem' }}>Welcome to Our Website</h1>
    //         </div>
    //     ) :   <div style={{
    //         backgroundColor: '#f8f9fa',
    //         padding: '2rem',
    //         marginBottom: '2rem',
    //         borderRadius: '0.3rem',
    //         boxShadow: '0 0.2rem 0.5rem rgba(0, 0, 0, 0.1)'
    //     }}>
    //         <h1 style={{ fontSize: '2.5rem', marginBottom: '1rem' }}>Welcome to Our Website</h1>
    //         <p style={{ fontSize: '1.2rem', lineHeight: '1.6', marginBottom: '1rem' }}>
    //             This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.
    //         </p>
    //         <p>
    //             <a href="/login" className="btn btn-primary btn-lg">LOGIN</a>
    //         </p>
    //         {isError !== null && (
    //             <div className="error-message">
    //                 {Array.isArray(isError) && isError.map((error, index) => (
    //                     <p key={index}>{error}</p>
    //                 ))}
    //                 <button onClick={handleClearError}>Dismiss</button>
    //             </div>
    //         )}
    //     </div>
    
 };

 const mapStateToProps = (state) =>({
    isAuthenticated:state.auth.isAuthenticated,
    isError: state.error.errorMessage,
    user: state.auth.user,
    loading: state.auth.loading,
    access:state.auth.access,
});

const mapDispatchToProps = (dispatch) => ({
    updateUser: (data) => dispatch({ type: UPDATE_USER, payload: data }),
    clearError: () => dispatch(clearError()),
});

export default connect(mapStateToProps,mapDispatchToProps)(UserDashboard);
// export default UserDashboard;