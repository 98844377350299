import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import "./card.scss"

export const Card = ({item, user, tokken}) => {
    const _item = item || {};
    const access  = tokken || {};
    const userinf = user  || {};
    console.log('card',_item,access)

     
    const navigate = useNavigate();  // Hook to navigate programmatically

    // Function to handle card or title click navigation
    const handleNavigate = () => {
        navigate(`/item/${_item.property_id}`, { state: { propertyData: _item, token: access } });
    };
  return (
    <div className='card' onClick={handleNavigate} >
        {/* <Link to={{
            pathname: `/item/${_item.property_id}`,
            state: { propertyData: _item, token: access },
        }} className='imagecontainer'>
            <img src={_item.image} alt=''/>
        </Link> */}
        <div className='imagecontainer'>
            <img src={_item.image} alt='' />
        </div>
        <div className="textcontainer">
            {/* <h1 className='title'>
            <Link to={{
            pathname: `/item/${_item.property_id}`,
            state: { propertyData: _item, token: access },
        }}>{item.property_name}</Link>
            </h1> */}
            <h1 className='title' onClick={handleNavigate}>  {/* Title click handler */}
             {_item.property_name}
            </h1>
            <p className='address'>
                <img src='/static/pin.png' alt=''/>
                <span>{_item.address1}</span>
            </p>
            {/* <p className='price'>&#8377 {_item.market_value}</p> */}
            <p className='price'>&#8377; {_item.market_value}</p>
            <div className="bottom">
                <div className="features">
                    <div className="feature">
                        <img src='/static/bath.png' alt=''/>
                        <span>{_item.bhk_type},BHK_Type</span>
                    </div>
                    <div className="feature">
                        <img src='/static/bath.png' alt=''/>
                        <span>{_item.built_up_area},Build_Area</span>
                    </div>
                </div>
                <div className="icons">
                    <div className="icon">
                        <img src='/static/save.png' alt=''/>
                    </div>
                    <div className="icon">
                        <img src='/static/chat.png' alt=''/>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
