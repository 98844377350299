import React from 'react'
import './list.scss'
import { listData } from '../../lib/dummydata'
import { Card } from '../card/Card'


export const List = ({properties, user, token}) => {
  const access  = token || {};
  const userinf = user  || {};
  console.log(access,user)
  return (
    <div className='list'>
        {properties.map(item =>(
          <Card key={item.id} item={item} user={userinf} tokken ={access}/>
        ))}
    </div>
  )
}
