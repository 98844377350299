import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { verify } from '../../actions/Auth';
import { useParams } from 'react-router-dom';

const Activate = ({verify}) => {
    const [verified,setVerified] = useState(false);
    const { uid, token } = useParams();
    const verifyAccount = e => {
        
        e.preventDefault();
        verify(uid, token);
        setVerified(true);
    };

    //isauthenticated?
    //if not redirect to login or if yes to home page
    if (verified){
        return <Navigate to='/'/>
    }

    return (

        <div className='homePage'>
            <div className='textContainer'>
                <div className='wrapper'>
                    <div className='title'>
                        <h1>Property Document Support for 12+ States in India</h1>
                    </div>
                    <div className='form'>
                        <h3>Verify Your Account</h3>
                        <form >
                            <button onClick={verifyAccount} type='button'> Verify </button>
                        </form>
                    </div>
                </div>
            </div>
            <div className='imgContainer'>
                <img src='/static/home3.png' alt=''/>
            </div>
        </div> 
       
        
    );
};



export default connect(null,{verify})(Activate);
