import React, { useState } from 'react'
import './slider.scss'

export const Slider = ({images}) => {
  console.log(images)
  const[imageIndex, setImageIndex] = useState(null);
  const changeSlides = (direction) => {
    {direction == "left" ?( imageIndex === 0 ? setImageIndex(images.length-1) : setImageIndex(imageIndex -1)) : (imageIndex === images.length-1 ? setImageIndex(0) :setImageIndex(imageIndex+1) )}
  }
  return (
    <div className='slider'>
      {imageIndex !== null && <div className="fullslider">
        <div className="arrow" onClick={() => changeSlides("left")}>
          <img src="/arrow.png" alt="" />
        </div>
        <div className="imgcontainer">
          <img src={images[imageIndex]} alt="" />
        </div>
        <div className="arrow" onClick={() => changeSlides("right")} >
          <img src="/arrow.png" className='right' alt="" />
        </div>
        <div className="close" onClick={() => setImageIndex(null)}>X</div>
      </div>}
       <div className="bigImage">
        <img src={images[0]} alt="" onClick={() => setImageIndex(0)} />
       </div>
       <div className="smallImages">
            {images.slice(1).map((image,index)=>(
                <img src={image} alt='' key={index} onClick={() => setImageIndex(index+1)} />
            ))}
       </div>
    </div>
  )
}
