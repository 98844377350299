import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { thunk } from 'redux-thunk';
import rootReducer from '../reducers/index';


const initialState = {}

const middleware = [thunk]

const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(...middleware)
    // composeWithDevTools(
    //     applyMiddleware(...middleware)
        // Add other enhancers here if needed
    // )
);

export default store;

