import React, { useRef, useState, useEffect } from 'react';
import '../home.scss'; // Import the CSS file

const BubbleScroll = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const items = [
    { name: 'Tamil Nadu', image: 'https://secira-documents.s3.eu-north-1.amazonaws.com/secira_frontend_images/TN1.jpg', text: 'Patta Chitta' },
    { name: 'Karnataka', image: 'https://secira-documents.s3.eu-north-1.amazonaws.com/secira_frontend_images/kA1.jpg', text: 'Bhoomi' },
    { name: 'Telangana', image: 'https://secira-documents.s3.eu-north-1.amazonaws.com/secira_frontend_images/TS1.jpg', text: 'Dharani' },
    { name: 'Andhra Pradesh', image: 'https://secira-documents.s3.eu-north-1.amazonaws.com/secira_frontend_images/AP1.jpg', text: 'Meebhoomi' },
    { name: 'Maharastra', image: 'https://secira-documents.s3.eu-north-1.amazonaws.com/secira_frontend_images/MH1.jpg', text: 'Bhulekh Mahabhumi' },
    { name: 'Uttar Pradesh', image: 'https://secira-documents.s3.eu-north-1.amazonaws.com/secira_frontend_images/UP1.jpg', text: 'Bhulekh' },
    { name: 'Kerala', image: 'https://secira-documents.s3.eu-north-1.amazonaws.com/secira_frontend_images/K1.jpg', text: 'E-Rekha' },
    { name: 'Gujarat', image: 'https://secira-documents.s3.eu-north-1.amazonaws.com/secira_frontend_images/GJ1.jpg', text: 'AnyRoR' },
    { name: 'Rajasthan', image: 'https://secira-documents.s3.eu-north-1.amazonaws.com/secira_frontend_images/RJ1.jpg', text: 'Apna Katha' },
    { name: 'Odisha', image: 'https://secira-documents.s3.eu-north-1.amazonaws.com/secira_frontend_images/OD1.jpg', text: 'Bhulekh' },
    { name: 'Bihar', image: 'https://secira-documents.s3.eu-north-1.amazonaws.com/secira_frontend_images/BH1.jpg', text: 'Bhulekh' },
    { name: 'Punjab', image: 'https://secira-documents.s3.eu-north-1.amazonaws.com/secira_frontend_images/PB1.jpg', text: 'Land Record' }
  ];

  const scrollContainerRef = useRef(null);

  useEffect(() => {
    // Set the initial active card to the center one
    const centerIndex = Math.floor(items.length / 2);
    setCurrentIndex(centerIndex);
  }, [items.length]);

  const scrollLeft = () => {
    setCurrentIndex(prev => Math.max(prev - 1, 0));
  };

  const scrollRight = () => {
    setCurrentIndex(prev => Math.min(prev + 1, items.length - 1));
  };

  return (
    <div className="bubble-scroll-container">
      <button className="scroll-button left" onClick={scrollLeft}>&#9664;</button>
      <div className="bubble-scroll-wrapper" ref={scrollContainerRef}>
        <div className="bubble-scroll-content">
          {items.map((item, index) => {
            // Determine the className based on the position
            let className = 'bubble-item';
            const distance = Math.abs(index - currentIndex);

            if (distance === 0) {
              className += ' active';
            } else if (distance === 1) {
              className += ' adjacent';
            } else if (distance === 2) {
              className += ' distant';
            } else if (distance === 3) {
              className += ' far';
            } else if (distance === 4) {
              className += ' far-far';
            } else if (distance === 5) {
              className += ' far-far-far';
            } else {
              className += ' far-far-far'; // For distances greater than 5
            }

            return (
              <div key={index} className={className}>
                <img src={item.image} alt={item.name} className="bubble-image" />
                <div className="bubble-text">
                  {item.name}<br /><span>{item.text}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <button className="scroll-button right" onClick={scrollRight}>&#9654;</button>
    </div>
  );
};

export default BubbleScroll;
