import React from 'react';
import './di.scss';


const ImageInput = ({ image, onChange, onDelete }) => {
  const handleChange = (e) => {
    const { name, files } = e.target;
    // Log if a file is attached
    if (files && files[0]) {
    console.log('File attached:', files[0].name);
    } else {
    console.log('No file attached.');
    }
    onChange({
      ...image,
      [name]: files ? files[0] : '',
    });
  };

  return (
    <div className="image-card">
        <div className="form-group">
            <label>Property Image:</label>
            <input type="file" name="propertyImage" onChange={handleChange} />
        </div>

        {/* Delete button */}
        <div className="form-group">
            <button type="button" onClick={onDelete} className="delete-image-btn">
            Delete Image
            </button>
        </div>
    </div>

    );
};

export default ImageInput;
