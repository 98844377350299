import React, { useState } from 'react';
import './profile.scss';

const ProfileUpdateForm = ({ user, onClose, onUpdate }) => {
    const [email, setEmail] = useState(user.email || '');
    const [mobile, setMobile] = useState(user.mobile || '');
    const [profileImage, setProfileImage] = useState(user.profileImage || '');

    const handleSubmit = (e) => {
        e.preventDefault();
        // Call the onUpdate function with the updated values
        onUpdate({ email, mobile, profileImage });
        onClose(); // Close the pop-up after submission
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setProfileImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div className="profile-update-form">
            <div className="form-container">
                <h2>Update Profile</h2>
                <form onSubmit={handleSubmit}>
                    <label>
                        Email:
                        <input 
                            type="email" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            required 
                        />
                    </label>
                    <label>
                        Mobile:
                        <input 
                            type="text" 
                            value={mobile} 
                            onChange={(e) => setMobile(e.target.value)} 
                            required 
                        />
                    </label>
                    <label>
                        Profile Image:
                        <input 
                            type="file" 
                            accept="image/*" 
                            onChange={handleImageChange} 
                        />
                    </label>
                    {profileImage && (
                        <div className="preview">
                            <img src={profileImage} alt="Profile Preview" />
                        </div>
                    )}
                    <div className="form-actions">
                        <button type="submit">Update</button>
                        <button type="button" onClick={onClose}>Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ProfileUpdateForm;
