import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link,Navigate } from 'react-router-dom';
import { reset_password } from '../../actions/Auth';

const ResetPassword = ({reset_password}) => {
    const [requestSent,setRequestSent] = useState(false);
    const [formdata,setFormdata] = useState({
        email:""
    });

    const { email } = formdata;

    const onChange = e => setFormdata({...formdata,[e.target.name]:e.target.value});
    const onSubmit = e => {
        e.preventDefault();
        reset_password(email);
        setRequestSent(true);
    };

    //isauthenticated?
    //if not redirect to login or if yes to home page
    if (requestSent){
        return <Navigate to='/'/>
    }

    return (
        <div className='homePage'>
            <div className='textContainer'>
                <div className='wrapper'>
                    <div className='title'>
                        <h1>Property Document Support for 12+ States in India</h1>
                    </div>
                    <div className='form'>
                        <h3>Request Password Reset</h3>
                        <form onSubmit={e=>onSubmit(e)}>
                            <div className='form-group'>
                                <input className='form-control'
                                        type='email'
                                        placeholder='Email'
                                        name='email'
                                        value={email}
                                        onChange={e => onChange(e)}
                                        required
                                />
                            </div>
                            <button type='submit'>Reset Password</button>
                        </form>
                    </div>
                </div>
            </div>
            <div className='imgContainer'>
                <img src='/static/home3.png' alt=''/>
            </div>
        </div> 
    );
};

const mapStateToProps = state =>({
    isAuthenticated:state.auth.isAuthenticated
});

export default connect(mapStateToProps,{reset_password})(ResetPassword);
