// ErrorAlert.js

import React,{ useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearNotification } from '../../actions/Notifications';
import './notifications.scss'

const Notifications = () => {
    const dispatch = useDispatch();
    const Message = useSelector(state => state.notifications.notification);
    console.log(Message)
    const handleClearnotifications = () => {
        dispatch(clearNotification());
    };

    // const notificationCounts = {};
    // if (Array.isArray(Message)) {
    //     Message.forEach(notification => {
    //         notificationCounts[notification] = (notificationCounts[notification] || 0) + 1;
    //     });
    // }

    return (
        <div className='notificationContainer'>
            {Message ?
                <div className="notificationBox" >
                    <ul>
                            <li>{Message}</li>

                    </ul>
                    <button className="clearButton" onClick={handleClearnotifications}>X</button>
                </div>
            :null}
        </div>
    );
};


export default Notifications;



